import { useState,useEffect } from 'react';
import PropTypes from 'prop-types'
import FilterChord from "./FilterChord"
import ChordDic from "./ChordDic"
import Collection from './Collection'
import AddItem from './AddItem'
import PinItem from './PinItem'
import DelItem from './DelItem'
import {FaChevronLeft,FaChevronRight} from 'react-icons/fa'

const ChordBoard = ({classname,isMobile,isDark,noteVol,
    collection,setCollection,showCollection,
    chords,onFilter,resetFilter,delChord,
    sharedChord,addSharedChord,pinItem,downsize,changePanelSize}) => {
    const [isShown, setIsShown] = useState(false)
    const [clickedId, setClickedId] = useState(0)
    const [ItemToMix, setItemToMix] = useState('')
    const [ItemToAdd, setItemToAdd] = useState('')
    const [customIDs, setCustomIDs] = useState(chords.filter((chord) => typeof(chord.customtag)== 'string').map((chord) => chord.id))
    const [chordInfo, setChordInfo] = useState('')
    useEffect(() => {
        if (clickedId!==0){
        const timeId = setTimeout(() => {
          // After 15 seconds set the show value to false
          setClickedId(0)
        }, 15000)
        return () => {
          clearTimeout(timeId)
        }}
      }, [clickedId]);
    useEffect(() => {
        if (chordInfo!==''){
        const timeId = setTimeout(() => {
          // After 15 seconds set the show value to false
          setChordInfo('')
        }, 15000)
        return () => {
          clearTimeout(timeId)
        }}
      }, [chordInfo]);
    useEffect(() => {
      //because user's chords have customtag in string, not undefined
      setCustomIDs(chords.filter((chord) => typeof(chord.customtag)== 'string').map((chord) => chord.id))
      }, [chords]);
    
    const onDelete = (id) =>{
        delChord(id)
    }
    const copyToAdd =(chord) =>{
      addSharedChord(sharedChord+chord)
    }
    const pressToDel =()=>{
      delChord(clickedId)
      setClickedId(0)
    }
    const pressToAdd =()=>{
      addSharedChord(sharedChord+ItemToAdd)
      setClickedId(0)
      setItemToAdd('')
    }
    const copyToMix =() =>{
      pinItem(ItemToMix)
      setClickedId(0)
      setItemToMix('')
    }

    return (
        <div className={classname}
        style={{boxShadow:isShown? '0 0 2px hsla(0deg, 0%, 0%, 0.5)':'none',width:!isMobile&&(downsize?'23%':'50%')}}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}>
            <FilterChord formclass={isDark?'addform addform-dark':'addform'} isDark={isDark}
            collection={collection} setCollection={setCollection} onFilter={onFilter} resetFilter={resetFilter}/>
            <ChordDic isDark={isDark} chords={chords} onDelete={onDelete} onFilter={onFilter}
              clickedId={clickedId} setClickedId={setClickedId} setChordInfo={setChordInfo}
              noteVol={noteVol} copyToAdd={copyToAdd} 
              setItemToAdd={setItemToAdd} setItemToMix={setItemToMix} collection={collection}/>
            {isMobile?null:
              ( downsize? 
              (<FaChevronRight className={isDark?'slide-chordboard slide-chordboard-dark':'slide-chordboard'}
              style={{display:isShown? 'block':'none'}} onClick={changePanelSize}/>)
              :(<FaChevronLeft className={isDark?'slide-chordboard slide-chordboard-dark':'slide-chordboard'}
              style={{display:isShown? 'block':'none'}} onClick={changePanelSize} />) 
              )}
            <Collection collection={collection} setCollection={setCollection} showCollection={showCollection}/>  
            {clickedId!==0 && chordInfo? <div className='chord-info'> {chordInfo} </div>:null }
            {clickedId!==0 && customIDs.includes(clickedId)? <DelItem addFunc={pressToDel}/>:null}    
            {clickedId!==0? <AddItem addFunc={pressToAdd}/>:null}           
            {clickedId!==0? <PinItem mixFunc={copyToMix} />:null}
            
        </div>
    )
}
ChordBoard.defaultProps ={
  isMobile:false,
  toDisplay:true
}
ChordBoard.propTypes ={
  isMobile: PropTypes.bool,
  toDisplay: PropTypes.bool
}
export default ChordBoard
