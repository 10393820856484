import logo from './logo2.png';
import PropTypes from 'prop-types'
import HeadButtons from './HeadButtons';
import Burger from './Burger';
import { Link } from 'react-router-dom'

const Header = ({isMobile,title,openInventory,showInventory,showAddtool,openAddtool,
    showPlaytool,openPlaytool,toggleModes,setShowApp}) => {
    return (
        <nav className = 'navigation'>
            <Link to='/'><img src={logo} alt="MuseByte Logo" height={50} width={50} /> </Link>
            <h1 style={{color: '4886A5'}}>{title}</h1>
            {
            isMobile?
            <Burger openInventory={openInventory} showInventory={showInventory}
            openAddtool={openAddtool} showAddtool={showAddtool} 
            openPlaytool={openPlaytool} showPlaytool={showPlaytool} 
            toggleModes={toggleModes} setShowApp={setShowApp}/>

            :<Link to='/' onClick={()=>setShowApp(true)}><HeadButtons openInventory={openInventory} showInventory={showInventory}
            openAddtool={openAddtool} showAddtool={showAddtool} 
            openPlaytool={openPlaytool} showPlaytool={showPlaytool}  /></Link>

            }
            
        </nav>
    )
}


Header.defaultProps={
    title: 'MuseByte',
    isMobile: false
}
Header.propTypes = {
    title:PropTypes.string.isRequired
}
export default Header
