import { useState,useEffect } from 'react'
import {FaTimes,FaPlay} from 'react-icons/fa'
import Chord from './Chord'
import { Play } from './utils'

const Phrase = ({phrase,clickedId,setClickedId,setPhraseInfo,
  onDelete,copyToAdd,setItemToUse,noteVol}) => {
  const [clickedIDinner, setClickedIDinner] = useState(0)
  const [extraclass, setExtraclass] = useState('')
  useEffect(() => {
    if (clickedId ===0){
      setExtraclass('')
    }
    if(clickedId!==0&& clickedId!==clickedIDinner){
      setExtraclass('')
    }

    if (clickedIDinner!==0){
    const timeId = setTimeout(() => {
      setExtraclass('')
      setClickedIDinner(0)
    }, 15000)
    return () => {
      clearTimeout(timeId)
    }}
    }, [clickedId,clickedIDinner,extraclass])

  const PlayorPause=(phrase) =>{
    Play([phrase.split(' ')],[Array(phrase.split(' ').length).fill(0)],100,noteVol*18.)
  }
  const chords = phrase.phrase.split(' ')
  const [isShown, setIsShown] = useState(false)
  const playAndOptions=(phrase,id)=>{
    if( clickedIDinner===0){
    setClickedId(id)
    setClickedIDinner(id)
    setExtraclass('more-shade')
    setItemToUse(phrase.phrase)
    if (phrase.name) setPhraseInfo(phrase.name)
    else setPhraseInfo('')
    }
    else if( clickedIDinner!==0) {
      setClickedId(0)
      setClickedIDinner(0)
      setExtraclass('less-shade')
      setItemToUse('')
      setPhraseInfo('')
    }
    //playNotes(chord)
  }
  /*
  useEffect(() => {
    isShown||clickedIDinner!==0?

  }, [isShown,clickedIDinner])*/
  return (
      <div className={['line',extraclass].join(" ")} 
      style={{border:isShown? 'rgb(135, 57, 168) 0.5px solid':'none'}}
      onDoubleClick={()=>copyToAdd(phrase.phrase)}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}>
    
        <div className='phrase' style={{right:isShown||clickedIDinner!==0?'76px':'0' }}
        onClick={()=>playAndOptions(phrase,phrase.id)} onDoubleClick={()=>copyToAdd(phrase.phrase)}>
        {chords.map((chord,index) => 
        (<Chord key={phrase.id*10+index} chord={chord} inPhrase={true} spacing="chord-inphrase"
        /> ) )}
        </div>
        <div className='phrase-btns'>
        {(isShown ||clickedIDinner!==0) && 
          <FaPlay className='LineIcon' style={{ cursor:'pointer',float:'left',display:'block'}} onClick={()=>PlayorPause(phrase.phrase)}/>}
        {phrase.customtag?  
          <FaTimes className='LineIcon' style={{color:'red', cursor:'pointer',display:isShown||clickedIDinner!==0? 'block':'none'}}
        onClick={() => onDelete(phrase.id)} />:null 
        }
        </div>
      </div>
  )
}
export default Phrase