const initialChordDic = [
    {id:1,
    name:'Augmented',
    pitchclass:'048',
    quality:'augmented',
    chord:'C3E3G#3',
    },
    {id:2,
    name:'Augmented major seventh',
    pitchclass:'048e',
    quality:'augmented',
    chord:'B3D#3G3A#3',
    },
    {id:3,
    name:'Augmented seventh',
    pitchclass:'048t',
    quality:'augmented',
    chord:'C3E3G#3A#3',
    },
    {id:6,
    name:'Diminished',
    pitchclass:'048',
    quality:'diminished',
    chord:'D4F#4A#4',
    },
    {id:7,
    name:'Dominant',
    pitchclass:'047',
    quality:'major',
    chord:'G4C5D#5',
    },
  ]
export {initialChordDic}
const initialPhraseDic = [
    {id:100,
    name:'minor but not sad',
    quality:'augmented',
    phrase:'C4E4G#4 A4D5F5 B4D5G5',
    },
    {id:102,
    quality:'augmented',
    phrase:'C3E3G#3B3 E3A3D4 D3F3A3 C4E4G#4B4 C4E4',
    },
    {id:103,
    quality:'augmented',
    phrase:'E5A5C6 D4F4A4 E5G#5B5 D4F4A4',
    },
    {id:104,
    name:'Moonlight sonata mov 1',
    quality:'augmented',
    phrase:'C#2C#3 B1B2 A1A2 G#1G#2 F#1F#2 F#1F#2',
    },
]
export {initialPhraseDic}