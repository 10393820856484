import { useState } from 'react';
import {FaTimes} from 'react-icons/fa'
const PopupSaveAdd = ({custom,setText,setHasText,togglePopup,saveCustom}) => {
  const [name,setName] = useState('')
  const [customtag, setCustomtag] = useState('')
  const onSave=(e)=>{    
    saveCustom({name,custom,customtag})
    setText('')
    setHasText(false)
    togglePopup()
    
  }
  return (
    <div className='popup'>
      <div className='popup-inner'>
        <form className='add-form-popup'>
          <div className='form-control-popup'>
          <label><h3>Add Chord/Phrase</h3></label>
          <p>Please add name: </p>
          <input type = 'text' placeholder="Name. Maximum 16 characters." value={name} 
          onChange={(e) => setName(e.target.value.slice(0,17))} />
          <p>Please add customized description for future search:</p>
          <input type = 'text' placeholder=" Description. Maximum 40 characters" value={customtag} onChange={(e) => setCustomtag(e.target.value.slice(0,41))} />
          </div>
        
          <button className='popup-btn' style = {{backgroundColor:'red'}} onClick={()=>togglePopup()}>Cancel</button>
          <button className='popup-btn' style = {{backgroundColor:'rgb(180,0,255)',marginLeft: '1%'}} onClick={()=>onSave()}>Save</button>
          <FaTimes className='cancel-fa' style={{color:'red',cursor:'pointer',display:'block'}}
            onClick={()=>togglePopup()} />
        </form>
      </div>
    </div>
  )
}

export default PopupSaveAdd