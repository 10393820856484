import {FaMapPin} from 'react-icons/fa'
import PropTypes from 'prop-types'
const PinItem = ({item,classname,mixFunc}) => {
    return (
        <FaMapPin className={classname} onClick={mixFunc}/>
    )
}
PinItem.defaultProps={
    classname: 'pin-item',
}
PinItem.propTypes = {
    classname:PropTypes.string.isRequired,
}

export default PinItem
