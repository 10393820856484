import Chord from "./Chord"
/*< Chord chord={text}/> */
const MainShowAdd = ({isDark,text}) => {
    const split_chords= text.split(' ').filter((el)=>el!=='') //split a phrase into separate chords
    const full_classname=(split_chords)=>{
        let darktag=''
        let showtag
        if (isDark) darktag='-dark'
        if (split_chords[0]) showtag='show'+darktag
        else showtag='hide'+darktag
        return 'main-showadd '+showtag
        }
    return (
        <div className={full_classname(split_chords)} >
            {split_chords[0] !=='' &&
            (split_chords.map((chord,index) => (
                < Chord key= {'ch'+index} chord={chord} inPhrase={true} spacing="chord-inadd" />))
            )}

        </div>
    )
}

export default MainShowAdd
