import { useState,useEffect } from 'react'
import { NoteOps } from './utils' ///playNotes should be from here
const Mixcell = ({absCellID,cellVal,slurSet,clickedId,setClickedId,noteVol}) => {
    let borderClass = 'cellborder-topbottom'
    if (slurSet[0] !== 1) borderClass +=' cellborder-left'
    if (slurSet[1] !== 1) borderClass +=' cellborder-right'
    if (slurSet[0] === 0 && slurSet[1]=== 0) borderClass = 'cellborder'

    const [clickedIDinner, setClickedIDinner] = useState(0)
    const [extraclass, setExtraclass] = useState(borderClass)
    const [root, setRoot] = useState('')
    useEffect(() => {
        if ( clickedId!==0 && clickedId!==clickedIDinner ){
            setExtraclass(borderClass)
            setClickedIDinner(0)
        }
        if ( clickedId === absCellID ){
            setExtraclass('cell-highlight')
            setClickedIDinner(absCellID)
        }
        if ( clickedId === 0 ){
            setExtraclass(borderClass)
            setClickedIDinner(0)
        }
    }, [clickedId,clickedIDinner,absCellID,borderClass])

    useEffect(() => {
        if (cellVal !==''){
            setRoot(cellVal[0]+'-root')
        }
        if (cellVal === ''){
            setRoot('')
        }
    }, [cellVal])
      
    const playAndOption=(cellVal,id)=>{
        if( clickedIDinner===0){        
            setClickedId(id)
            setClickedIDinner(id)
            setExtraclass('cell-highlight')}
        else if( clickedIDinner!==0) {
            setClickedId(0)
            setClickedIDinner(0)
            setExtraclass('cellborder')
        }        
    }

    return (
        <div className={['cell fit-margin',root, extraclass].join(" ")}
        onClick={()=>playAndOption(cellVal,absCellID)}>
            {NoteOps.separateNotes(cellVal,true).join('')}            
        </div>
    )
}

export default Mixcell
