import PropTypes from 'prop-types'
import { useState, useEffect} from "react"
import {FaPlay,FaTimes} from 'react-icons/fa'
import {FaLongArrowAltUp,FaLongArrowAltDown
    ,FaLongArrowAltRight,FaLongArrowAltLeft
    }from 'react-icons/fa'
import Mixline from "./Mixline"
import MixTempo from './MixTempo'
import { NoteOps, Durations, Play } from './utils'
import Button from './Button'

const MixplayBoard = ({classname,top,pinnedItem,noteVol,toggleBoard}) => {
    const [isShown, setIsShown] = useState(false)
    const lineIDs = [100,200,300]
    const [clickedId, setClickedId] = useState(0)
    const [numOfCells, setNumOfCells] = useState(19)
    //const [SelectCoorIni, setSelectCoorIni] = useState([])
    //const [SelectCoorFin, setSelectCoorFin] = useState([])
    const [pinnedChords, setPinnedChords] = useState([])
    const [ItemToEdit, setItemToEdit] = useState('')
    const [beatLength, setBeatLength] = useState(1./4)
    const [bpm, setBpm] = useState(120)
    const [playVol, setPlayVol] = useState(16)
    const [data, setData] = useState([...Array(lineIDs.length)].map(e => Array(numOfCells).fill('')))
    const [lengthData, setLengthData] = useState([...Array(lineIDs.length)].map(e => Array(numOfCells).fill(0)))    
    const [ResetClicked, setResetClicked] = useState(false)
    const [action, setAction] = useState(null)
    //const [playOn, setPlayOn] = useState(false)
    const changeBeatLength=() =>{
        if (beatLength === 1.) setBeatLength(1./16)
        else setBeatLength(beatLength*2)
    }

    //console.log(MixboardRef.current.screenX)
    //const rect = MixboardRef.current.getBoundingClientRect()
    //console.log(rect.left,rect.top)
    useEffect(() => {
        if (pinnedItem.split(' ').length + clickedId%100 >numOfCells && clickedId!==0){
            let newNumofCells = Math.min(32, Math.max(pinnedItem.split(' ').length+ clickedId%100,numOfCells+5))     
            if (newNumofCells> numOfCells){       
            lengthData.map(row =>row.push(...Array(newNumofCells - numOfCells).fill(0)))
            data.map(row =>row.push(...Array(newNumofCells - numOfCells).fill('')))
            setData(data)  
            setLengthData(lengthData)              
            setNumOfCells(newNumofCells)  
            }
        }
    }, [pinnedItem,clickedId,numOfCells,data,lengthData])
    //pin Item from other boards to cells
    useEffect(() => {
        if (pinnedItem !==''){
            setPinnedChords(pinnedItem.split(' ').map((chord) => NoteOps.separateNotes(chord).join('')))
        }
    }, [pinnedItem])
    //autodel pinned Chords so they aren't copied to other clicked cells
    useEffect(() => {
        if (pinnedChords.length > 0){
            if (clickedId !== 0){
                var clickedCellID = clickedId %100
                var clickedLineID = (clickedId - clickedCellID)/100-1
                for (var i = 0; i < pinnedChords.length; i++) {
                    data[clickedLineID][clickedCellID+i] = pinnedChords[i]
                }
                setData(data)
                setPinnedChords([])
                setClickedId(0)
                }            
            else{
                const timeId = setTimeout(() => {
                setPinnedChords([])
                setClickedId(0)
                }, 8000)
                return () => {
                clearTimeout(timeId)
                }}
            }
    }, [pinnedChords,clickedId,data])

    /*  
    useEffect(() => {
        if (SelectCoorFin!==[]&& SelectCoorIni!==[]&&
            (Math.abs(SelectCoorIni[0]-SelectCoorIni[0])>20||Math.abs(SelectCoorIni[1]-SelectCoorIni[1])>20)
            ) console.log(SelectCoorIni,SelectCoorFin)
    }, [SelectCoorIni,SelectCoorFin])*/
    const PlayorPause=() =>{
        Play(data,lengthData,bpm,playVol)
    }
    //onMouseDown={(e) =>setSelectCoorIni([e.screenX,e.screenY])} onMouseUp={(e) =>setSelectCoorFin([e.screenX,e.screenY])}
    useEffect(() => {
        if (clickedId!==0){
            const timeId = setTimeout(() => {
            setClickedId(0)
            }, 24000)
            if (ResetClicked) {
                clearTimeout(timeId)
                setResetClicked(false)
                const timeIdAgain = setTimeout(() => {
                    setClickedId(0)
                    }, 24000)
                return () => {
                    clearTimeout(timeIdAgain)
                    }
            }
            else return () => {
            clearTimeout(timeId)
            }}
      }, [clickedId,ResetClicked,ItemToEdit])
    useEffect(() => {
        if (clickedId !== 0){
            var clickedCellID = clickedId %100
            var clickedLineID = (clickedId - clickedCellID)/100 - 1
            setItemToEdit(data[clickedLineID][clickedCellID]) 
        }                       
    }, [clickedId,data])
    useEffect(() => {
        if (clickedId === 0){
            setItemToEdit('')
        }
    }, [clickedId])
    const downPitchCell = (clickedId) =>{
        let clickedCellID = clickedId %100
        let clickedLineID = (clickedId - clickedCellID)/100 - 1
        data[clickedLineID][clickedCellID] = NoteOps.downPitch(data[clickedLineID][clickedCellID])
        setData(data)
        setItemToEdit(data[clickedLineID][clickedCellID])
        setResetClicked(true)
    }
    const upPitchCell = (clickedId) =>{
        let clickedCellID = clickedId %100
        let clickedLineID = (clickedId - clickedCellID)/100 - 1
        data[clickedLineID][clickedCellID] = NoteOps.upPitch(data[clickedLineID][clickedCellID])
        setData(data)
        setItemToEdit(data[clickedLineID][clickedCellID])
        setResetClicked(true)
    }
    //have to use Effect for instant change of grid size and cell class
    useEffect(() => {
        if (action && clickedId !== 0){
            let clickedCellID = clickedId %100
            let clickedLineID = (clickedId - clickedCellID)/100 - 1
            if (action === 1) {
                setAction(null)
                if (clickedCellID > 0 && data[clickedLineID][clickedCellID] !== '' ){
                    data[clickedLineID][clickedCellID-1] = data[clickedLineID][clickedCellID]
                    data[clickedLineID][clickedCellID] = ''
                    lengthData[clickedLineID][clickedCellID] = 0
                    setData(data)
                    setLengthData(lengthData)
                    setClickedId(clickedId - 1)
                    setItemToEdit(data[clickedLineID][clickedCellID-1])
                    setResetClicked(true)
                }
                }
            if (action === 2){
                setAction(null)
                if (clickedCellID <= numOfCells-2 && data[clickedLineID][clickedCellID] !== '' ){
                    if (numOfCells === clickedCellID + 2){       
                        let newNumofCells = Math.min(32, numOfCells + 5)
                        lengthData.map(row =>row.push(...Array(newNumofCells - numOfCells).fill(0)))
                        data.map(row =>row.push(...Array(newNumofCells - numOfCells).fill('')))
                        setData(data)  
                        setLengthData(lengthData)              
                        setNumOfCells(newNumofCells)  
                    }
                    data[clickedLineID][clickedCellID+1] = data[clickedLineID][clickedCellID]
                    data[clickedLineID][clickedCellID] = ''
                    lengthData[clickedLineID][clickedCellID] = 0                    
                    setData(data)
                    setLengthData(lengthData)
                    setClickedId(clickedId + 1)
                    setItemToEdit(data[clickedLineID][clickedCellID+1])
                    setResetClicked(true)
                }
            }
            if (action === 3){
                setAction(null)
                if (lengthData[clickedLineID][clickedCellID] === 1){
                    lengthData[clickedLineID][clickedCellID] = 0
                    setLengthData(lengthData)
                }
                else if (data[clickedLineID][clickedCellID] !== '' 
                && clickedCellID<= numOfCells -1){
                    if (numOfCells === clickedCellID + 1){       
                        let newNumofCells = Math.min(32, numOfCells + 5)
                        lengthData.map(row =>row.push(...Array(newNumofCells - numOfCells).fill(0)))
                        data.map(row =>row.push(...Array(newNumofCells - numOfCells).fill('')))
                        setData(data)  
                        setLengthData(lengthData)              
                        setNumOfCells(newNumofCells)  
                    }
                    if (data[clickedLineID][clickedCellID+1] !== undefined){
                        if (data[clickedLineID][clickedCellID+1] === ''){
                            data[clickedLineID][clickedCellID+1] = data[clickedLineID][clickedCellID]
                            setData(data)
                        }
                        lengthData[clickedLineID][clickedCellID] = 1
                        setLengthData(lengthData)                                
                    }
                    
                }
            }
            if (action === 4){
                setAction(null)
                data[clickedLineID][clickedCellID] = ''
                if (lengthData[clickedLineID][clickedCellID] === 1) {
                    lengthData[clickedLineID][clickedCellID] = 0                
                    setLengthData(lengthData)
                    }
                if (lengthData[clickedLineID][clickedCellID-1] === 1){
                    lengthData[clickedLineID][clickedCellID-1] = 0
                    setLengthData(lengthData)
                    }
                setData(data)
                setClickedId(0)
                setItemToEdit('')
                
            }               
        }
    }, [action,clickedId,data,lengthData,numOfCells])
    const changePlayVol = (e) => {    
        setPlayVol(e.target.value)
    }
    return (
        <div style={{top:top,boxShadow:isShown? '0 0 2px hsla(0deg, 0%, 0%, 0.5)':'none'}}
        className={classname} 
        onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}
        >
            {isShown&&<FaTimes className='cancel-fa' style={{color:'rgb(121, 108, 126)',cursor:'pointer',display:'block'}}
            onClick={()=>toggleBoard()} />}
            <MixTempo beatNote={Durations.durationSymbols[Durations.noteDurations.indexOf(beatLength)]} bpm={bpm}
                changeBeatLength={changeBeatLength} changeBpm={setBpm}/>
            
            <FaPlay className='playmix playmix-on' style={{ cursor:'pointer'}} onClick={PlayorPause}/>
            {(clickedId === 0 || ItemToEdit === '') && <input type="range" step="1" min="0" max="20" value={playVol}
            className='change-playvol' onChange={changePlayVol}/>}
            {clickedId !== 0 && ItemToEdit !== ''?
            <FaLongArrowAltDown className='mixbtn downPitch' style={{cursor:'pointer'}} onClick={()=>downPitchCell(clickedId)}
             />:null
            }
            {clickedId !== 0 && ItemToEdit !== ''?
            <FaLongArrowAltUp className='mixbtn upPitch' style={{cursor:'pointer'}} onClick={()=>upPitchCell(clickedId)}
            />:null
            }
            {clickedId !== 0 && ItemToEdit !== ''?
            <FaLongArrowAltLeft className='mixbtn movebeat-left' style={{cursor:'pointer'}} onClick={()=>setAction(1)}
            />:null
            }
            {clickedId !== 0 && ItemToEdit !== ''?
            <FaLongArrowAltRight className='mixbtn movebeat-right' style={{cursor:'pointer'}} onClick={()=>setAction(2)}
             />:null
            }
            {clickedId !== 0 && ItemToEdit !== ''?
            <Button classname='mixbtn slur' color='transparent' text ={')'} onClick={()=>setAction(3)} />:null
            }
            {clickedId !== 0 && ItemToEdit !== ''?
            <FaTimes className='mixbtn delCell' style={{cursor:'pointer'}}
            onClick={()=>setAction(4)}/>:null 
            }
            
            <div className='full-cellnote'>{ItemToEdit}</div>
            <div className='mixplayboard'>
            {lineIDs.map((lineID) =>(
                <Mixline key={lineID} lineID={lineID} cellNum={numOfCells}
                clickedId={clickedId} setClickedId={setClickedId} 
                lineVals={data[lineID/100-1]} slurVals={lengthData[lineID/100-1]}
                noteVol={noteVol}/>
            ))
            }            
            </div>
        </div>
    )
}
MixplayBoard.defaultProps ={
    classname:'mixplaycontainer'
}
MixplayBoard.propTypes ={
    classname: PropTypes.string
}

export default MixplayBoard
