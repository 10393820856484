import {FaAngleDoubleDown} from 'react-icons/fa'
import PropTypes from 'prop-types'
const AddItem = ({classname,addFunc}) => {
    return (
        <FaAngleDoubleDown className={classname} onClick={addFunc}/>
    )
}
AddItem.defaultProps={
    classname: 'add-item',
}
AddItem.propTypes = {
    classname:PropTypes.string.isRequired,
}

export default AddItem
