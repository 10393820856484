import PropTypes from 'prop-types'
import {FaDiceD6,FaUserTag,FaUserSlash,FaBackspace} from 'react-icons/fa'
const Collection = ({collection,setCollection,showCollection,classname}) => {
    const setAndShowCollection=(val)=>{
        setCollection(val)
        showCollection(val)
    }  
    return (
        collection===0?
            (<FaDiceD6 className={classname} onClick={()=>setAndShowCollection(1)}/>)
            :(collection===1?<FaUserSlash className={classname} onClick={()=>setAndShowCollection(2)}/>
            :(collection===2?<FaUserTag className={classname} onClick={()=>setAndShowCollection(0)}/>
            :<FaBackspace className={classname} onClick={()=>setAndShowCollection(0)}/>) )
               
    )
}
Collection.defaultProps={
    classname: 'collection',
}
Collection.propTypes = {
    classname:PropTypes.string.isRequired,
}
export default Collection
