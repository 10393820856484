import Phrase from "./Phrase"
const PhraseDic = ({isDark,onFilter,maxHeight,phrases,
    clickedId,setClickedId,setPhraseInfo,
    onDelete,copyToAdd,setItemToUse,collection,noteVol}) => {
    const full_classname=()=>{
        let darktag=''
        if (isDark) darktag='phraseboard-dark'
        if(maxHeight) return ['phraseboard',darktag,'height1'].join(' ')
        else return ['phraseboard',darktag,'height0'].join(' ')
    }
    var isListofTags = false
    if (typeof(phrases[0])=='string') {
        isListofTags= true
        var tags=Array.from(new Set(phrases))
        }

    return (
        <div className={full_classname()}>
            {phrases.length>0?(isListofTags?
            (tags.map((tag) => (typeof(tag)=='string'?<h3 className='tag'>{tag}</h3>:null)))
            
            :(phrases.map((phrase) => (
                < Phrase key= {phrase.id} phrase={phrase} clickedId={clickedId} setClickedId={setClickedId} 
                setPhraseInfo={setPhraseInfo} onDelete={onDelete} copyToAdd={copyToAdd} 
                setItemToUse={setItemToUse} noteVol={noteVol}/>
            ))))
            :<h3 style={{padding:'10px 10px',lineHeight:'24px'}}> No chord block found matching the search/category.<br/>
                {collection!==2 && collection!==0 && 'See full lists of tags'} 
                {collection!==2 && collection!==0 && <span style={{textDecoration:'underline',cursor:'pointer',fontSize:'16px',paddingLeft:'5px',fontFamily: 'sans-serif'}} 
                onClick={()=>onFilter('list of tags')}>'here'</span>}
            </h3>
            }
            
        </div>
    )
}

export default PhraseDic
