import { useState} from "react"
const MixTempo = ({beatNote,bpm,changeBeatLength,changeBpm}) => {
    const [enteringBpm, setEnteringBpm] = useState(false)
    const [bpmInput, setBpmInput] = useState(0)
    const onChangeBpmInput = (e) => {      
        setBpmInput(e.target.value)
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (Math.floor(event.target.value) === 0 || !Math.floor(event.target.value)) {
                setBpmInput(90)
                changeBpm(90)
            }
            else{
                setBpmInput(Math.floor(event.target.value))
                changeBpm(Math.floor(bpmInput))
            }
            setEnteringBpm(false)
            
        }
      }
    return (
        <div className='tempo-control'>
            <div className='tempo-note' onClick={()=>changeBeatLength()}>
                <div className='tempo-note-inner'>{beatNote}</div></div>
            <div className='tempo-equal'> = </div>
            {enteringBpm?
                <input className='tempo-input' type='number' step='1' placeholder ={bpm}
                value={bpmInput} onChange={onChangeBpmInput} onKeyDown={handleKeyDown}/>
                :<div className='tempo-bpm' onClick={()=>setEnteringBpm(true)}> 
                
                {bpm} 
                </div>}
        </div>
    )
}

export default MixTempo
