import { useState,useEffect, useRef} from 'react'
import { BrowserRouter as Router, Route} from 'react-router-dom'
import {lazy,Suspense} from 'react'
import Header from './components/side-components/Header'
import Footer from './components/side-components/Footer'
import About from './components/side-components/About'
import Welcome from './components/side-components/Welcome'
import Inventories from './components/Inventories'
import ChordBoard from './components/ChordBoard'
import PhraseBoard from './components/PhraseBoard'
import MainAddboard from './components/MainAddboard'
import MainPiano from './components/MainPiano'
import MixplayBoard from './components/MixplayBoard'
import { initialChordDic, initialPhraseDic } from './dics'
import NoteVolume from './components/side-components/NoteVolume'
import ModeToggle from './components/side-components/ModeToggle'


//import PopUpNew from './components/PopUpNew.js'
function App() {
  const [width, setWidth] = useState(window.innerWidth)
  const [isMobile, setIssMobile] = useState(width<724)

  useEffect(() => {    
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize)
    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [])
  useEffect(() => {
    setIssMobile(width<724)
  }, [width])
  //mobile mode control
  const [showModeBtnsMobile, setShowModeBtnsMobile] = useState(false)
  //get custom settings & data
  var musedic
  window.localStorage.getItem('MuseDic')?(musedic=JSON.parse(window.localStorage.getItem('MuseDic'))):(musedic=[])
  const [customChords, setCustomChords] = useState(musedic[0]?musedic[0]:[])
  const [customPhrases, setCustomPhrases] = useState(musedic[1]?musedic[1]:[])
  const [maxChordID, setMaxChordID] = useState(musedic[2]?musedic[2]:700)
  const [maxPhraseID, setMaxPhraseID] = useState(musedic[3]?musedic[3]:800)
  const [oldUser, setOldUser] = useState(musedic[4]>0?musedic[4]:0.5)
  //const [panelPage, setPanelPage] = useState(musedic[5]?musedic[5]:0)
  const [showWelcome, setShowWelcome] = useState(musedic[5]?musedic[5]:1)
  const [showInventory, setShowInventory] = useState(false)
  const [showAddtool, setShowAddtool] = useState(false)
  const [showPlaytool, setShowPlaytool] = useState(false)

  const [darkmode, setDarkmode] = useState(musedic[6]?musedic[6]:0)
  const [showApp, setShowApp] = useState(true)
  const [footerHeight, setFooterHeight] = useState(540)
  const PopUpNew = lazy(() => import('./components/popups/PopUpNew.js'))
  useEffect(() => {
    if (oldUser===0.5){        
    const timeId = setTimeout(() => {
      setOldUser(0)
    }, 4000)
    return () => {
      clearTimeout(timeId)
    }}
  }, [oldUser]);

  //console.log('1st',maxChordID,maxPhraseID,musedic[0].length)
  const [collection, setCollection] = useState(0)
  const [chords, setChords] = useState(collection===0?initialChordDic.concat(customChords)
  :(collection===1?initialChordDic:customChords))
  const [currentChordDic, setCurrentChordDic] = useState(collection===0?initialChordDic.concat(customChords)
  :(collection===1?initialChordDic:customChords))
  
  const [collectionPhrase, setCollectionPhrase] = useState(0)
  const [phrases, setPhrases] = useState(collectionPhrase===0?initialPhraseDic.concat(customPhrases)
  :(collectionPhrase===1?initialPhraseDic:customPhrases))
  const [currentPhraseDic, setCurrentPhraseDic] = useState(collectionPhrase===0?initialPhraseDic.concat(customPhrases)
  :(collectionPhrase===1?initialPhraseDic:customPhrases))

  const [noteVol, setNoteVol] = useState(1)
  const [downsize, setDownsize] = useState(true)
  const [maxHeightPhraseBoard, setMaxHeightPhraseBoard] = useState(false)

  const [sharedChord, setSharedChord] = useState('')
  const [pinnedItem, setPinnedItem] = useState('')
  const [hasText, setHasText] = useState(false)//if the add box has input text
  const [foundChords, setFoundChords] = useState([])
  const [foundPhrases, setFoundPhrases] = useState([])
  //const [height, setHeight] = useState(112);
  const AddboardRef = useRef(null);
  /*useEffect(() => {
    setHeight(AddboardRef.current.clientHeight+24);
  }, []); */
  
  const initChorddic = useRef(initialChordDic)
  //Update inventories instantly
  useEffect(() => {
    setChords(collection===0?initChorddic.current.concat(customChords)
    :(collection===1?initChorddic.current:(collection ===2?customChords:foundChords)))
  }, [customChords,collection,foundChords])
  const initPhraseDic = useRef(initialPhraseDic)
  useEffect(() => {
    setPhrases(collectionPhrase===0?initPhraseDic.current.concat(customPhrases)
  :(collectionPhrase===1?initPhraseDic.current:(collectionPhrase===2?customPhrases:foundPhrases)))
  }, [customPhrases,collectionPhrase,foundPhrases]) 
  //clear up items to be pinned to mixboard
  useEffect(() => {
    if (pinnedItem!==''){        
    const timeId = setTimeout(() => {
      setPinnedItem('')
    }, 200)
    return () => {
      clearTimeout(timeId)
    }}
  }, [pinnedItem]); 
  useEffect(() => {
    if (sharedChord !== '') !showAddtool && setShowAddtool(true)
  }, [sharedChord,showAddtool])
  useEffect(() => {
    if (showWelcome||!showApp){
      setFooterHeight(Math.max(570,0.88*window.innerHeight))
    }
    else{
        let top = 160
        if (isMobile) {
          if (showInventory) top += 300
          if (showPlaytool) top+= 310
          if (showAddtool) top += 390
          if (maxHeightPhraseBoard) top+= 380
        }
        else{
          if (showInventory) top += 280
          if (showPlaytool) top+= 300
          if (showAddtool||maxHeightPhraseBoard) top += 390
        }
        setFooterHeight(Math.max(top,0.88*window.innerHeight))      
    }
  }, [isMobile,showWelcome,showApp,showInventory,showAddtool,showPlaytool,maxHeightPhraseBoard])

  //toggle inventory boards
  const toggleShowInventory =() =>{
    if(!showInventory) setShowWelcome(false)
    setShowInventory(!showInventory)
    setMaxHeightPhraseBoard(false)
  }
  //toggle add boards
  const toggleShowAddtool =() =>{
    if(!showAddtool) setShowWelcome(false)
    if (showAddtool ) {
      sharedChord !== '' && setSharedChord('')
      setHasText(false)
    }
    setShowAddtool(!showAddtool)
  }
  const toggleShowPlaytool =() =>{
    if(!showPlaytool) setShowWelcome(false)
    setShowPlaytool(!showPlaytool)
  }
  /*useEffect(() => {
    if (!showApp && (showInventory||showAddtool||showPlaytool))
      setShowAddtool(true)
  }, [showApp,showInventory,showAddtool,showPlaytool])*/
  //show/choose original dictionary or custom collection or both 
  const showCollection =(selection) =>{
    setCollection(selection)
    setCurrentChordDic(selection===0?initialChordDic.concat(customChords)
    :(selection===1?initialChordDic:customChords))
  }

  const showCollectionPhrase =(selection) =>{
    setCollectionPhrase(selection)
    setCurrentPhraseDic(selection===0?initialPhraseDic.concat(customPhrases)
    :(selection===1?initialPhraseDic:customPhrases))
  }
  // filter for stored chords/phrases basing on keywords
  const searchChord = (keyword) =>{
    setCollection(3)
    if (keyword.toLowerCase().includes('tags')||keyword.toLowerCase().includes('list')){
      setFoundChords(currentChordDic.map((chord) => chord.quality||(chord.customtag!=='1'?chord.customtag:null) ))
    }
    else setFoundChords(currentChordDic.filter((chord) => (chord.quality && chord.quality.includes(keyword))|| 
    (chord.name && chord.name.includes(keyword))||(chord.customtag && chord.customtag.includes(keyword))||
    chord.chord.toLowerCase().includes(keyword) ))
  }
  const searchPhrase = (keyword) =>{
    setCollectionPhrase(3)
    if (keyword.toLowerCase().includes('tags')||keyword.toLowerCase().includes('list')){
      setFoundPhrases(currentPhraseDic.map((phrase) => phrase.quality||(phrase.customtag!=='1'?phrase.customtag:null) ))
    }
    else setFoundPhrases(currentPhraseDic.filter((phrase) => (phrase.name && phrase.name.includes(keyword))||
    (phrase.quality && phrase.quality.includes(keyword))|| 
    (phrase.customtag && phrase.customtag.includes(keyword)) || phrase.phrase.toLowerCase().includes(keyword)))
  }

  //clear state
  const resetSearchChord = (selection) => {
    setCollection(selection)
    //setChords(selection===0?initialChordDic.concat(customChords)
    //:(selection===1?initialChordDic:customChords))
  }
  const resetSearchPhrase = (selection) => {
    setCollectionPhrase(selection)
    //setPhrases(selection===0?initialPhraseDic.concat(customPhrases)
    //:(selection===1?initialPhraseDic:customPhrases))
  }
  // toggle chord dictionary board
  const changePanelSize=() =>{
      setDownsize(!downsize)
  }
  //extend phraseboard  
  const changePhraseBoardHeight=()=>{
    if (!maxHeightPhraseBoard) setShowAddtool(true)
    setMaxHeightPhraseBoard(!maxHeightPhraseBoard)
    
  }
  //toggle mode buttons in mobile mode
  const toggleModeBtns=() =>{
    setShowModeBtnsMobile(!showModeBtnsMobile)
  }
  useEffect(() => {
    if (showModeBtnsMobile === true){
      const timeId = setTimeout(() => {
        // After 14 seconds set the show value to false
        setShowModeBtnsMobile(false)
      }, 18000)
      return () => {
        clearTimeout(timeId)
      }}
    }, [showModeBtnsMobile]);

  //set play chord when single click or not
  const controlNoteVol=() =>{
    noteVol === 0? setNoteVol(0.5):(noteVol === 0.5?setNoteVol(1):setNoteVol(0))
  }
  //set to dark mode
  const controlDarkMode=() =>{
    setDarkmode(!darkmode)
  }
  //add chord or phrase  
  const saveCustom = (custom) =>{
    var data
    if (custom.customtag ==='') custom.customtag='1'
    if( custom.custom.split( ' ' ).length>1){
      data={id:maxPhraseID+1,name:custom.name,phrase:custom.custom,customtag:custom.customtag}
      window.localStorage.setItem(
        "MuseDic", JSON.stringify([customChords,[...customPhrases,data],maxChordID,(maxPhraseID+1),oldUser,0,Number(darkmode)]))
      
      setMaxPhraseID(maxPhraseID+1)  
      setCustomPhrases([...customPhrases,data])      
    }
    else{
      data={id:maxChordID+1,name:custom.name,chord:custom.custom,customtag:custom.customtag}
      window.localStorage.setItem(
        "MuseDic", JSON.stringify([[...customChords,data],customPhrases,(maxChordID+1),maxPhraseID,oldUser,0,Number(darkmode)]))
      setMaxChordID(maxChordID+1)
      setCustomChords([...customChords,data])
    }
  }
  const deleteChord = (id) =>{
    //await fetch(`http://localhost:5000/customs/${id}`,
    //{method: 'DELETE'})
    
    // setChords(chords.filter((chord) => chord.id !== id))
    const filtered = customChords.filter((chord) => chord.id !== id).map((chord) => chord.id>id?{...chord,id:chord.id-1}:chord)
    setChords(chords.filter((chord) => chord.id !== id))
    window.localStorage.setItem(
      "MuseDic", JSON.stringify([filtered,customPhrases,(maxChordID-1),maxPhraseID,oldUser,0,Number(darkmode)]))    
    setMaxChordID(maxChordID-1)  
    //setCustomChords(customChords.map((chord) => chord.id>id?{...chord,id:chord.id-1}:chord))
    setCustomChords(filtered)
  }
  const deletePhrase = (id) =>{
    const filtered = customPhrases.filter((phrase) => phrase.id !== id).map((phrase) => phrase.id>id?{...phrase,id:phrase.id-1}:phrase)
    setPhrases(phrases.filter((phrase) => phrase.id !== id))
    window.localStorage.setItem(
      "MuseDic", JSON.stringify([customChords,filtered,maxChordID,(maxPhraseID-1),oldUser,0,Number(darkmode)]))    
    setMaxPhraseID(maxPhraseID-1)  
    setCustomPhrases(filtered)
  }

  const give_allclass=(classname) =>{
    let mobileclass = ''
    let darkclass = ''
    if (isMobile) mobileclass=classname+'-mobile'
    if (darkmode) darkclass = classname+'-dark'
    return [classname,mobileclass,darkclass].join(' ')
  }
  const togglePopupNew=()=>{
    setOldUser(1)
  }
  
  useEffect(() => {
    window.addEventListener('beforeunload', 
    window.localStorage.setItem(
      "MuseDic", JSON.stringify([customChords,customPhrases,maxChordID,maxPhraseID,oldUser, Number(showWelcome),Number(darkmode)])))
    return () => {
        window.removeEventListener('beforeunload', window.localStorage.setItem(
          "MuseDic", JSON.stringify([customChords,customPhrases,maxChordID,maxPhraseID,oldUser,Number(showWelcome),Number(darkmode)])))
    }
  })
  return (
    <Router>
    <div className={give_allclass('container')} >
      {showWelcome||(!showInventory&&!showAddtool&&!showPlaytool)?
            showApp&&<Welcome isMobile={isMobile} darkmode={darkmode} closeWelcome={setShowWelcome} 
            openInventory={setShowInventory} openAddtool={setShowAddtool}/>:null}
      <Header isMobile={isMobile} openInventory={toggleShowInventory} showInventory={showInventory}
      openAddtool={toggleShowAddtool} showAddtool={showAddtool} 
      openPlaytool={toggleShowPlaytool} showPlaytool={showPlaytool}
      toggleModes={isMobile?toggleModeBtns:null} setShowApp={setShowApp}/>

      {isMobile?(showModeBtnsMobile?
      <ModeToggle darkmode={darkmode} controlDarkMode={controlDarkMode} giveClass={give_allclass('modetoggle')} />:null)
      :<ModeToggle darkmode={darkmode} controlDarkMode={controlDarkMode} giveClass={give_allclass('modetoggle')}/>
      }

      {oldUser>=0.5?null:
      <Suspense fallback={<div>Loading...</div>}>
        <PopUpNew togglePopup={togglePopupNew}/>
        </Suspense>
      }
      <Route path='/'
          exact
          render={(props) => (
            <>
            
            
            {isMobile&&(showInventory?
            <Inventories isDark={darkmode} noteVol={noteVol} 
            chordboardclass={give_allclass('chordboard-wrap')} collection={collection} setCollection={setCollection} 
            showCollection={showCollection} chords={chords} searchChord={searchChord} 
            resetFilter={resetSearchChord} delChord={deleteChord} 
            sharedChord={sharedChord} addSharedChord={setSharedChord} pinItem={setPinnedItem} 
            phraseboardclass={give_allclass('phraseboard-wrap')}
            collectionPhrase={collectionPhrase} setCollectionPhrase={setCollectionPhrase} 
            showCollectionPhrase={showCollectionPhrase} phrases={phrases} 
            searchPhrase={searchPhrase} resetSearchPhrase={resetSearchPhrase} delPhrase={deletePhrase}
            maxHeight={maxHeightPhraseBoard} changeHeight={setMaxHeightPhraseBoard} />:null)}
            
            {!isMobile&&showInventory&&
            <ChordBoard classname={give_allclass('chordboard-wrap')} isDark={darkmode} noteVol={noteVol}
            collection={collection} setCollection={setCollection} 
            showCollection={showCollection} chords={chords} onFilter={searchChord} resetFilter={resetSearchChord} 
            delChord={deleteChord} sharedChord={sharedChord} addSharedChord={setSharedChord}
            pinItem={setPinnedItem} downsize={downsize} changePanelSize={changePanelSize}/>}
            {!isMobile&&showInventory&&
            <PhraseBoard classname={give_allclass('phraseboard-wrap')} isDark={darkmode} noteVol={noteVol}
            collection={collectionPhrase} setCollection={setCollectionPhrase} showCollection={showCollectionPhrase}
            phrases={phrases} onFilter={searchPhrase} resetFilter={resetSearchPhrase}
            delPhrase={deletePhrase} sharedChord={sharedChord} addSharedChord={setSharedChord} 
            pinItem={setPinnedItem}
            downsize={downsize} changePanelSize={changePanelSize}
            maxHeight={maxHeightPhraseBoard} changeHeight={changePhraseBoardHeight}/>
            }
            

            <div className={showInventory?(maxHeightPhraseBoard&&isMobile? 'add-mixplay-panel mainboard-down':'add-mixplay-panel mainboard-mid')
              :'add-mixplay-panel mainboard-up'}>
              {showAddtool && <MainAddboard isMobile={isMobile} isDark={darkmode} noteVol={noteVol}
              forwardedRef={AddboardRef} boardclass={give_allclass('addboard')} 
              width={isMobile?'88%':(maxHeightPhraseBoard?(downsize?'23%':'50%'):'84%') }
              saveCustom={saveCustom} setHasText={setHasText} 
              sharedChord={sharedChord} setSharedChord={setSharedChord}/>}

              {showAddtool && <MainPiano classname={give_allclass('pianoboard')} 
              top={hasText?'176px':'122px'} width={isMobile?'88%':(maxHeightPhraseBoard?(downsize?'23%':'50%'):'84%')}
              noteVol={noteVol} sharedChord={sharedChord} setSharedChord={setSharedChord} 
              pinnedItem={pinnedItem} pinItem={setPinnedItem}/>}
              {showPlaytool &&
              <MixplayBoard classname={give_allclass('mixplaycontainer')} 
              top={showAddtool? (hasText?'420px':'366px'):(isMobile?'0px':(maxHeightPhraseBoard?'366px':'0px'))} 
              pinnedItem={pinnedItem} toggleBoard={toggleShowPlaytool}/>}
            </div>

            {isMobile?(showModeBtnsMobile?
            <NoteVolume noteVol={noteVol} controlNoteVol={controlNoteVol} noteVolClass="Volume-mobile"/>:null)
            :(showWelcome||(!showInventory&&!showAddtool&&!showPlaytool)? null:
            <NoteVolume noteVol={noteVol} controlNoteVol={controlNoteVol} noteVolClass="Volume"/>)
            }


            

            </>
          )}
        />

      <Route path='/about' >
            <About setShowApp={setShowApp} showApp={showApp}/>
      </Route>
      <Footer top={footerHeight.toString()+'px'} showApp={showApp} setShowApp={setShowApp}/>

    </div>
    </Router >
  );
}

export default App;
