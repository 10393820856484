import { useState } from 'react';
import {FaPlay,FaSave,FaTimes} from 'react-icons/fa'
import PopupSaveAdd from './popups/PopupSaveAdd'
import PopUpError from './popups/PopUpError'
import { NoteOps,Play } from './utils'
const MainAdd = ({isMobile,text,setText,setHasText,sharedChord,setSharedChord,saveCustom,noteVol}) => {
    
    const [errors, setErrors] = useState([])
    var outcome
    const instantDisplay = (e) => {    
        if (!text && sharedChord){
            e.target.value=sharedChord
        }
        setSharedChord('')
        if(e.target.value.length>0)
        {setHasText(true)}
        if(e.target.value.length===0)
        {setHasText(false)}

        setText(e.target.value.split('').map((char)=> 'Bb#x'.includes(char)?char:char.toUpperCase()).join(''))                
    }
    //saving and popup for comments/tags on chords
    const [showPopup, setShowPopup] = useState(false)
    const [showPopupErr, setShowPopupErr] = useState(false)
    
    const onSave = (e) =>{

        e.preventDefault()
        if((!text && !sharedChord) || text.split(' ').join('') === '') {
            alert('Please add a chord or try editing first!')
            if (text.length<=1)
            setHasText(false) 
            return
        }
        else if (!text && sharedChord){
            e.target.value=sharedChord
        }
        if (text.split(' ').filter((el)=>el!=='').length >16){
            setErrors(['You block has more than 16 chords, which is not supported in this composing method.'])
            togglePopupError()
            return
        }
        outcome = NoteOps.validChords(text)
        if (outcome !== '0'){
            let errorVals = outcome.split('')
            let errorList = errorVals.map((val)=>NoteOps.errors[val])
            setErrors(errorList)
            togglePopupError()
            return
        }
        else{
            togglePopup()
            if (text.length<1) setHasText(false)  
        
        }       
    }
    const togglePopup=() => {
    setShowPopup( !showPopup)
    }
    const togglePopupError=() => {
        setShowPopupErr(!showPopupErr)
        }
    // Play or Pause in main add
    const [play, setPlay] = useState(false)
    const PlayorPause=(e) =>{
        setPlay(!play)

        e.preventDefault()
        if(!text) {
            alert('Please add a chord and/or try editing first!')
            if (text.length<=1)
            setHasText(false) 
            return
        }
        if( text.split(' ').join('') === ''){
            setHasText(false) 
            return
        }
        if (text.split(' ').filter((el)=>el!=='').length >16){
            setErrors(['You block has more than 16 chords, which is not supported in this composing method.'])
            togglePopupError()
            return
        }
        outcome = NoteOps.validChords(text)
        if (outcome !== '0'){
            let errorVals = outcome.split('')
            let errorList = errorVals.map((val)=>NoteOps.errors[val])
            setErrors(errorList)
            togglePopupError()
            return
        }
        else{
            
            let phrase = text.split(' ').filter((chord)=>chord!=='').join(' ')
            Play([phrase.split(' ')],[Array(phrase.split(' ').length).fill(0)],100,noteVol*18.)

            
        }
    }
    const eraseText=(e)=>{
        setText('')
        setSharedChord('')
        setHasText(false)  
    }
    return (
        <div>
            <form className='add-form-addboard' onSubmit={onSave}>
                <div className='form-control-addboard'>
                <div className={isMobile?'label-mobile':'label'}>Add Chord/Phrase</div>
                <input type = 'text' placeholder="Add chord(s), using space to separate chords. 
                For e.g A2C#3E3, A3C4E4 A3Db4F5" value={text+sharedChord} onChange={instantDisplay} />
                </div>
                {text||sharedChord?<FaTimes className='delChordInputIcon' onClick={eraseText} />:null}
                
                <FaPlay className='AddIcon' style={{ cursor:'pointer'}} onClick={PlayorPause}/>
                
                <FaSave className='AddIcon' style={{ cursor:'pointer'}} 
                onClick={onSave}/> 
                
                              

            </form>
            {showPopup ? 
            <PopupSaveAdd custom={text.split(' ').filter((chord)=>chord!=='').join(' ')} setText={setText} setHasText={setHasText} togglePopup={togglePopup} saveCustom={saveCustom}/>
            : null }
            {showPopupErr? <PopUpError errors={errors} togglePopup={togglePopupError}/>:null}
            
        </div>
    )
}

export default MainAdd
