import { useState } from 'react'
import {FaSearch,FaTimes} from 'react-icons/fa'
import PropTypes from 'prop-types'
const FilterChord = ({formclass,isDark,collection,onFilter,resetFilter,placeholdertext}) => {
    const [keyword,setKeyword] = useState('')
    const onSubmit = (e) => {      
        e.preventDefault()  
        if(!keyword){return}
        onFilter(keyword)
    }
    const onChangeKeyword=(e) =>{        
        setKeyword(e.target.value)
        //resetFilter(collection)
        if(e.target.value===''&&collection===3)resetFilter(0)
    }
    const eraseText=(e)=>{
        setKeyword('')
    }
    return (
        <form className={formclass} onSubmit={onSubmit}>
            <div className={isDark?'form-control form-control-dark':'form-control'}>
                <input type='text' placeholder ={placeholdertext}
                value={keyword} onChange={onChangeKeyword}
                />

            </div>

            {keyword&&<FaTimes className='delInputIcon' onClick={eraseText} />}
            <FaSearch type='submit' className="BoardIcon" style={{ cursor:'pointer'}}
            onClick={onSubmit}/>
        </form>
    )
}
FilterChord.defaultProps={
    formclass:'addform',
    placeholdertext: 'Quick find chords e.g major, dominant'
}
FilterChord.propTypes = {
    formclass:PropTypes.string.isRequired,
    placeholdertext:PropTypes.string.isRequired
}
export default FilterChord
