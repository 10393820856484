import Mixcell from "./Mixcell"
const Mixline = ({lineID,cellNum,lineVals,slurVals,clickedId,setClickedId,noteVol}) => {
    const cellIDs= [...Array(cellNum).keys()]    
    return (
        <div className='mixplayline'>
            {cellIDs.map((cellID)=>(
                <Mixcell key={lineID+cellID} absCellID={lineID+cellID} cellVal={lineVals[cellID]}
                slurSet={[slurVals[cellID-1],slurVals[cellID]]}
                clickedId={clickedId} setClickedId={setClickedId} noteVol={noteVol}/>
            ))
            }            
        </div>
    )
}
export default Mixline
