import { useState} from "react"
import ChordBoard from "./ChordBoard"
import PhraseBoard from "./PhraseBoard"

const Inventories = ({isDark,noteVol,chordboardclass,
    collection,setCollection,showCollection,
    chords,searchChord,resetSearchChord,deleteChord,
    sharedChord,addSharedChord,pinItem,
    phraseboardclass,
    collectionPhrase,setCollectionPhrase,showCollectionPhrase,
    phrases,searchPhrase,resetSearchPhrase,delPhrase,
    maxHeight,changeHeight}) => {
    const [rightside, setRightside] = useState(false)
    const changeSide = () =>{
        if (rightside === true) { changeHeight(false) }
        setRightside(!rightside)
    }
    return (
        <div className='inventories'>
            {!rightside && <ChordBoard isDark={isDark} noteVol={noteVol} classname={chordboardclass} isMobile={true} 
            collection={collection} setCollection={setCollection} showCollection={showCollection}
            chords={chords} onFilter={searchChord} resetFilter={resetSearchChord} 
            delChord={deleteChord} sharedChord={sharedChord} addSharedChord={addSharedChord}
            pinItem={pinItem}/>}
            <div className='turn-burger'  onClick={()=>changeSide()}>
                <div></div>
                <div></div>
            </div>
            {rightside && <PhraseBoard isDark={isDark} noteVol={noteVol} classname={phraseboardclass} isMobile={true} 
            collection={collectionPhrase} setCollection={setCollectionPhrase} showCollection={showCollectionPhrase}
            phrases={phrases} onFilter={searchPhrase} resetFilter={resetSearchPhrase}
            delPhrase={delPhrase} sharedChord={sharedChord} addSharedChord={addSharedChord} 
            pinItem={pinItem} maxHeight={maxHeight} changeHeight={()=>changeHeight(!maxHeight)}/>}  
            
        </div>
    )
}

export default Inventories
