import {FaTimes} from 'react-icons/fa'
import PropTypes from 'prop-types'
const DelItem = ({classname,addFunc}) => {
    return (
        <FaTimes className={classname} onClick={addFunc}/>
    )
}
DelItem.defaultProps={
    classname: 'del-item',
}
DelItem.propTypes = {
    classname:PropTypes.string.isRequired,
}

export default DelItem