
const Key = ({keyname,givePressedKey,copyToAdd}) => {
    var keyclass
    if (keyname.includes('b')||keyname.includes('#'))
        {keyclass = 'black-key '+keyname+'-key'   }
    else {keyclass = 'white-key '+keyname+'-key' }
    const playNoteAndMore=(keyname)=>{
        givePressedKey(keyname)
        copyToAdd(keyname)
    }
    return (
        <div className={keyclass} onClick={()=>playNoteAndMore(keyname)}></div>
    )
}
export default Key
