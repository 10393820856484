import Chord from "./Chord"
const ChordDic = ({isDark,chords,onFilter,onDelete,clickedId, setClickedId,setChordInfo,
    noteVol,copyToAdd,setItemToAdd,setItemToMix,collection}) => {
        var isListofTags = false
        if (typeof(chords[0])=='string') {
            isListofTags= true
            var tags=Array.from(new Set(chords))
            }
        return (
        <div className='chordboard'>
            
            {chords.length>0? (isListofTags?
            (tags.map((tag) => (typeof(tag)=='string'?<h3 className='tag'>{tag}</h3>:null)))
            :(chords.map((chord) => (
                < Chord key= {chord.id} isDark={isDark} spacing="chord-indic"  
                chord={chord} clickedId={clickedId} setClickedId={setClickedId} 
                setChordInfo={setChordInfo} onDelete={onDelete} copyToAdd={copyToAdd} 
                setItemToAdd={setItemToAdd} setItemToMix={setItemToMix}/>
            )))) 
            :<h3 style={{padding:'10px 10px',lineHeight:'24px'}}> No chord found matching the search/category.<br/>
                {collection!==2 && collection!==0 && 'See full lists of tags'} 
                {collection!==2 && collection!==0 && <span style={{textDecoration:'underline',cursor:'pointer',fontSize:'16px',paddingLeft:'5px',fontFamily: 'sans-serif'}} 
                onClick={()=>onFilter('list of tags')}>'here'</span>}
            </h3>
            }
        </div>
    )
}


export default ChordDic
