import Button from "../Button"
const HeadButtons = ({toggleModes,openInventory,showInventory,showAddtool,openAddtool,showPlaytool,openPlaytool}) => {
    return (
        <div className='btn-container'>
            <Button color ={showInventory? 'rgb(180,0,255)':'rgb(200, 90, 255)'} text ={'Inventory'} onClick={openInventory}/>
            <Button color ={showAddtool? 'rgb(150,0,220)':'rgb(165, 90, 255)'} text ={showAddtool?'Addbox X':'Add Bytes!'} onClick={openAddtool}/>
            <Button color ={showPlaytool? 'rgb(132,3,197)':'rgb(132,3,220)'}
             text ={showPlaytool?'Playbox X':'Play Bytes'} onClick={openPlaytool} />
        </div>
    )
}

export default HeadButtons
