import PropTypes from 'prop-types'

const Button = ({classname,color, text, onClick}) => {
    return (
        <div
            onClick = {()=>onClick()}
            
            className = {classname}
            style = {{backgroundColor : color}}
        >
            {text}
        </div>
    )
}
Button.defaultProps ={
    color: 'var(--primary)',
    classname:'btn'
}
Button.propTypes ={
    classname: PropTypes.string,
    text: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
}
export default Button