import PropTypes from 'prop-types'
import { useState,useEffect } from 'react';
import {FaTimes} from 'react-icons/fa'
import { getChord,NoteOps } from './utils.js';
const Chord = ({isDark,inPhrase,isCustom,chord,
    clickedId,setClickedId,setChordInfo,
    spacing,onDelete,copyToAdd,setItemToAdd,setItemToMix}) => {
    /* chord name contains chord range already*/
    /*const rangeclass = 'chord'+range */
    const [clickedIDinner, setClickedIDinner] = useState(0)
    const [extraclass, setExtraclass] = useState('less-shade')
    useEffect(() => {
        if (clickedId ===0){
            setExtraclass('less-shade')
        }
        if(clickedId!==0&& clickedId!==clickedIDinner){
            setExtraclass('less-shade')
        }

        if (clickedIDinner!==0){
        const timeId = setTimeout(() => {
          setExtraclass('less-shade')
          setClickedIDinner(0)// clickID already taken care of in Chordboard
        }, 15000)
        return () => {
          clearTimeout(timeId)
        }}
      }, [clickedId,clickedIDinner,extraclass])
    var root
    if (chord.chord){
        root = chord.chord[0]+'-root'
    }else{
        root = chord[0]+'-root'
    }
    var show_chord=NoteOps.separateNotes(getChord(chord),true).join('')
    const [isShown, setIsShown] = useState(false)
    const playAndOptions=(chord,id)=>{
        if(clickedIDinner===0){
            var notes = getChord(chord)
            setClickedId(id)
            setClickedIDinner(id)
            setExtraclass('more-shade')
            setItemToAdd(notes)
            setItemToMix(notes)
            setChordInfo(chord.name?(chord.name+' : '+chord.chord):chord.chord)}
        else if( clickedIDinner!==0) {
            setClickedId(0)
            setClickedIDinner(0)
            setExtraclass('less-shade')
            setItemToAdd('')
            setItemToMix('')
            setChordInfo('')
        }
    }


    return (
        <div className ={['chord',spacing, root, extraclass].join(" ")}
        onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}
        onClick={()=>!inPhrase&&playAndOptions(chord,chord.id)} onDoubleClick={()=>!inPhrase&&copyToAdd(show_chord)}>
            {show_chord}
            {chord.customtag||isCustom?
            <FaTimes className='delChord' style={{cursor:'pointer',display:isShown? 'block':'none'}}
            onClick={() => onDelete(chord.id)} />:null 
            }
        </div>
    )
}
Chord.defaultProps={
    range: '4',
    spacing:'',
    //extraclass:'less-shade',
    inPhrase:false,
    isCustom:false,
}
Chord.propTypes = {
    range:PropTypes.string.isRequired,
    //extraclass:PropTypes.string.isRequired,
    inPhrase:PropTypes.bool.isRequired,
    isCustom:PropTypes.bool.isRequired
}
export default Chord 
