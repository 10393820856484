import MainAdd from "./MainAdd"
import MainShowAdd from "./MainShowAdd";
import { useState} from "react";
const MainAddboard = ({isMobile,isDark,noteVol,forwardedRef,boardclass,width,
    saveCustom,setHasText,sharedChord,setSharedChord}) => {
    const [isShown, setIsShown] = useState(false);
    const [text, setText] = useState('')

    return (
        <div ref={forwardedRef} className={boardclass}
        style={{width:width,boxShadow:isShown? '0 0 5px hsla(0deg, 0%, 0%, 0.5)':'0 0 2px hsla(0deg, 0%, 0%, 0.5)'}} 
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}>
            <MainAdd isMobile={isMobile} noteVol={noteVol}
            text={text} setText={setText} setHasText={setHasText}
            sharedChord={sharedChord} setSharedChord={setSharedChord} saveCustom={saveCustom}/>
            <MainShowAdd isDark={isDark} text={text} setText={setText}/>
        </div>
    )
}

export default MainAddboard
