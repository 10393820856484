import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import {FaCaretLeft} from 'react-icons/fa'
const About = ({setShowApp,showApp}) => {
  useEffect(() => {
    if (showApp === true){
      setShowApp(false)
    }
  }, [showApp,setShowApp])
  return (
    <div className='about'>
      <div className='slogan'>Structuring your creativity better</div>
      <p className='about-body'>You are using MuseByte Version 1.0.0. MuseByte aims to help you sharpen
        your music statements by forming blocks of chords.<br/>
        The idea of creating compositions
        from small blocks has been present since the famous symphony "Faith" of Beethoven. 
        It is also applicable to writing songs. Once you get a backbone idea a.k.a a verse,
        the rest is about mixing/orchestrating around it. <br/>
        In addition, with MuseByte,
        you can focus more on the side of music theory. You can also improvise on saved ideas or lines already available 
        in the library. As a result, MuseByte can also help avoid over-duplications.
      </p>
      <h3> Thank you for using MuseByte! Enjoy building blocks! </h3>

      <Link to='/' className='goback' onClick={()=>setShowApp(true)}>
        <FaCaretLeft className='goback-icon'/>
        <p className='goback-text'>Go Back</p>
        </Link>
    </div>
  )
}

export default About