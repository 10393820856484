import {Link} from 'react-router-dom'
const Footer = ({top,setShowApp}) => {

    //<a href='/about'>About</a>
    return (
        <footer style={{top:top}}>
            <p style={{color:'var(--tool)'}}>Copyright &copy; MuseByte 2021</p>
            <Link to='/about' style={{color:'var(--tool)'}}
            onClick={()=>setShowApp(false)}>About</Link>
        </footer>
    )
}

export default Footer
