export function getChord(chord) {
    if (chord.chord)
        return chord.chord
    else if(typeof(chord)=="string")
        return chord
}
const NoteOps = {
    basis: {
        notes:'CDEFGAB',
        stepsToNext:[2,2,1,2,2,2,1],
        stepsToPrev:[-1,-2,-2,-1,-2,-2,-2],
        octaves:[0,1,2,3,4,5,6,7,8],
        accidentals:'b#x',
        accidental_steps:[-1,1,2]
    },
    keynames:['A0','Bb0','B0','C1','Db1','D1','Eb1','E1','F1','Gb1','G1','Ab1',
    'A1','Bb1','B1','C2','Db2','D2','Eb2','E2','F2','Gb2','G2','Ab2',
    'A2','Bb2','B2','C3','Db3','D3','Eb3','E3','F3','Gb3','G3','Ab3',
    'A3','Bb3','B3','C4','Db4','D4','Eb4','E4','F4','Gb4','G4','Ab4',
    'A4','Bb4','B4','C5','Db5','D5','Eb5','E5','F5','Gb5','G5','Ab5',
    'A5','Bb5','B5','C6','Db6','D6','Eb6','E6','F6','Gb6','G6','Ab6',
    'A6','Bb6','B6','C7','Db7','D7','Eb7','E7','F7','Gb7','G7','Ab7',
    'A7','Bb7','B7','C8'],
    equivalents:{
        'C#':'Db',
        'D#':'Eb',
        'F#':'Gb',
        'G#':'Ab',
        'A#':'Bb',
    },
    errors:{
        1:'Some note name(s) is/are incorrect or expected.',
        2:'The chord has more than 2 accidentals for one single note, which is not supported.',
        3:'Some characters are not correct note names or accidentals.',
        4:'An octave range indicator is missing.',
        5:'Octave number is higher/lower than the supported range for this note.'
    },
    refineNote: function(note,halfRefine=false) {
        ///to refine notes with too many accidentals
        if ((note.length<=4 && halfRefine === true) || note.length === 2)
            return note
        if (note.length<=3 && this.keynames.includes(note))
            return note
        var head = note[0]
        var range = parseInt(note.slice(-1))
        var head_index = this.basis.notes.indexOf(note[0])
        var N_step = 0
        var accis = note.slice(1,-1)

        for (var i = 0; i < accis.length; i++){
            let acci = accis[i]
            N_step += this.basis.accidental_steps[this.basis.accidentals.indexOf(acci)]
            if (N_step>0){
                if (N_step>=this.basis.stepsToNext[head_index]){
                    N_step -= this.basis.stepsToNext[head_index]
                    if (head === 'B'){
                        head = 'C'  
                        head_index = 0
                        range +=1}
                    else{ 
                        head_index +=1
                        head = this.basis.notes[head_index]}  
                }
                else if (N_step<this.basis.stepsToNext[head_index]){
                    continue;
                }
            }
            else if (N_step<0){
                if (N_step <= this.basis.stepsToPrev[head_index]){  
                    N_step -= this.basis.stepsToPrev[head_index]  
                    if (head === 'C'){
                        head = 'B'  
                        head_index = 6
                        range -=1}
                    else 
                        {head_index -=1
                        head = this.basis.notes[head_index]}                      
                }
                else if (N_step>this.basis.stepsToPrev[head_index]){
                    continue;
                }
            }
            else if (N_step === 0){
                continue
            } 
        }
        if (N_step === 0){
            return head+range
        } 
        else if (N_step > 0){

            if (N_step === this.basis.stepsToNext[head_index]){
                if (head === 'B'){
                    head = 'C'  
                    head_index = 0
                    range +=1}
                else{ 
                    head_index +=1
                    head = this.basis.notes[head_index]}
                return head + range
                }
            else return this.basis.notes[head_index+1]+'b'+range
        }
        else if (N_step < 0){
            return head+'b'+range
        }  
    },
    getKeyname: function(note){
        var headAccis = note.slice(0,-1)
        if (this.equivalents[headAccis])
        return this.equivalents[headAccis]+note.slice(-1)
        else return note
    },
    separateNotes: function(chord,noteOnly){
        //console.log('to',chord)
        if (noteOnly === undefined) noteOnly = false
        if (!this.basis.octaves.includes(parseInt(chord.slice(-1)))
            || !this.basis.notes.includes(chord[0])){
            return [chord]}
        var range_ids=[]
        var note_list=[]
        for (var i = 0; i < chord.length; i++) {
            if (parseInt(chord[i])>=0)
                range_ids.push(i)
        }    
        for (var j=0; j<range_ids.length; j++){
            if (noteOnly === true){
                if (j === 0) note_list.push(chord.slice(0,range_ids[j]))
                else { 
                if (!note_list.includes(chord.slice(range_ids[j-1]+1,range_ids[j])))
                note_list.push(chord.slice(range_ids[j-1]+1,range_ids[j]))
                }
            }
            else{
                if (j === 0) {
                    note_list.push(chord.slice(0,range_ids[j]+1))
                }
                else { 
                    if (!note_list.includes(chord.slice(range_ids[j-1]+1,range_ids[j]+1)))
                        note_list.push(chord.slice(range_ids[j-1]+1,range_ids[j]+1))
                }
            }
        }
        return note_list
    },
    validNote: function(note){
        if (!this.basis.notes.includes(note[0])) return '1'
        if (isNaN(parseInt(note.slice(-1)))) return '4'
        if (parseInt(note.slice(-1))>8) return '5'
        if (note.slice(1,-1).length>2) return '2'
        for (var i = 1; i < note.length-1; i++) {
            if (this.basis.accidentals.includes(note[i]) !== true ) 
            return '3'
        }
        if(!this.keynames.includes(this.refineNote(note))) return '15'
        return '0'
    },
    validChord: function(chord){
        let noteList = this.separateNotes(chord)
        let incorrects = noteList.filter((el)=>el.length<2)
        if (incorrects.length>0) return '14'     
        var error
        for (var i = 0; i < noteList.length; i++) {
            error= this.validNote(noteList[i])
            if (error!=='0') return error
        }
        return '0'
    },
    validChords: function(text) {
        var chords = text.split(' ').filter((chord)=>chord!=='') 
        var error
        for (var i = 0; i < chords.length; i++) {
            if (!this.passFirstCheck(chords[i])) return '3'
            error = this.validChord(chords[i])
            if (error!=='0') return error
        }
        return '0'
    },
    passFirstCheck: function(text) {
        for (var i = 0; i < text.length; i++) {
            if ('cdefgab #x'.includes(text[i].toLowerCase()) === false && (parseInt(text[i])>=0)===false)
            return false
        }
        return true
    },
    upPitchNote:function(note) {
        //assume accidental has been refined to b or #, not x or more
        var head = note[0]
        var range = parseInt(note.slice(-1))
        var head_index = this.basis.notes.indexOf(note[0])
        var acci = note.slice(1,-1)
        var N_step = acci?this.basis.accidental_steps[this.basis.accidentals.indexOf(acci)]+1:1
        acci =''
        if (N_step === this.basis.stepsToNext[head_index] ){            
            range += head === 'B'?1:0
            head = this.basis.notes[head_index+1]||this.basis.notes[0]
        }
        else if (N_step < this.basis.stepsToNext[head_index] ){
            switch(N_step) {
                case 0:
                    acci = ''
                    break
                case 1:
                    acci = '#'
                    break
                default:
                    break
              }
        }      
        var newnote = head + acci + range
        return this.keynames.includes(this.getKeyname(newnote))?newnote:note
    },
    downPitchNote:function(note) {
        //assume note has been refined
        var head = note[0]
        var range = parseInt(note.slice(-1))
        var head_index = this.basis.notes.indexOf(note[0])
        var acci = note.slice(1,-1)
        var N_step = acci?this.basis.accidental_steps[this.basis.accidentals.indexOf(acci)]-1:-1
        acci =''
        if (N_step === this.basis.stepsToPrev[head_index] ){            
            range += head === 'C'?-1:0
            head = this.basis.notes[head_index-1]||this.basis.notes[6]
        }
        else if (N_step > this.basis.stepsToPrev[head_index] ){
            if (N_step === 0) acci = ''
            else acci = 'b'
        }      
        var newnote = head + acci + range
        return this.keynames.includes(this.getKeyname(newnote))?newnote:note
    },
    upPitch: function(chord) {
        var noteList = this.separateNotes(chord)
        if (noteList.includes('C8'))
            return chord
        //console.log('list',noteList,chord)
        //parseInt(note.slice(-1))<7?note.slice(0,-1)+ (parseInt(note.slice(-1))+1).toString():note)
        return noteList.map((note)=> this.refineNote(note)).map((note)=>this.upPitchNote(note)).join('')
    },
    downPitch: function(chord) {
        var noteList = this.separateNotes(chord)  
        if (noteList.includes('A0'))
            return chord
        //parseInt(note.slice(-1))>0?note.slice(0,-1)+ (parseInt(note.slice(-1))-1).toString():note)
        return noteList.map((note)=> this.refineNote(note)).map((note)=>this.downPitchNote(note)).join('')
    }
}
export {NoteOps}

export const keynames=['A0','Bb0','B0','C1','Db1','D1','Eb1','E1','F1','Gb1','G1','Ab1',
    'A1','Bb1','B1','C2','Db2','D2','Eb2','E2','F2','Gb2','G2','Ab2',
    'A2','Bb2','B2','C3','Db3','D3','Eb3','E3','F3','Gb3','G3','Ab3',
    'A3','Bb3','B3','C4','Db4','D4','Eb4','E4','F4','Gb4','G4','Ab4',
    'A4','Bb4','B4','C5','Db5','D5','Eb5','E5','F5','Gb5','G5','Ab5',
    'A5','Bb5','B5','C6','Db6','D6','Eb6','E6','F6','Gb6','G6','Ab6',
    'A6','Bb6','B6','C7','Db7','D7','Eb7','E7','F7','Gb7','G7','Ab7',
    'A7','Bb7','B7','C8']
export const Durations = {
    noteDurations : [1./16,1./8,1./4,1./2,1.],
    durationNames : ['semiquaver','quaver','quarter','half','whole'],
    durationSymbols: ["\uD834\uDD61","\uD834\uDD60","\uD834\uDD5F", "\uD834\uDD5E",	"\uD834\uDD5D"]
}
var sampleRate = 44100;
export const noteNumbers={
    'A0':21,'Bb0':22,'B0':23,'C1':24,'Db1':25,'D1':26,'Eb1':27,'E1':28,
    'F1':29,'Gb1':30,'G1':31,'Ab1':32,'A1':33,'Bb1':34,'B1':35,'C2':36,
    'Db2':37,'D2':38,'Eb2':39,'E2':40,'F2':41,'Gb2':42,'G2':43,'Ab2':44,
    'A2':45,'Bb2':46,'B2':47,'C3':48,'Db3':49,'D3':50,'Eb3':51,'E3':52,
    'F3':53,'Gb3':54,'G3':55,'Ab3':56,'A3':57,'Bb3':58,'B3':59,'C4':60,
    'Db4':61,'D4':62,'Eb4':63,'E4':64,'F4':65,'Gb4':66,'G4':67,'Ab4':68,
    'A4':69,'Bb4':70,'B4':71,'C5':72,'Db5':73,'D5':74,'Eb5':75,'E5':76,
    'F5':77,'Gb5':78,'G5':79,'Ab5':80,'A5':81,'Bb5':82,'B5':83,'C6':84,
    'Db6':85,'D6':86,'Eb6':87,'E6':88,'F6':89,'Gb6':90,'G6':91,'Ab6':92,
    'A6':93,'Bb6':94,'B6':95,'C7':96,'Db7':97,'D7':98,'Eb7':99,'E7':100,
    'F7':101,'Gb7':102,'G7':103,'Ab7':104,'A7':105,'Bb7':106,'B7':107,'C8':108,
    'A#0':22,'C#1':25,'D#1':27,'F#1':30,'G#1':32,
    'A#1':34,'C#2':37,'D#2':39,'F#2':42,'G#2':44,
    'A#2':46,'C#3':49,'D#3':51,'F#3':54,'G#3':56,
    'A#3':58,'C#4':61,'D#4':63,'F#4':66,'G#4':68,
    'A#4':70,'C#5':73,'D#5':75,'F#5':78,'G#5':80,
    'A#5':82,'C#6':85,'D#6':87,'F#6':90,'G#6':92,
    'A#6':94,'C#7':97,'D#7':99,'F#7':102,'G#7':104,
    'A#7':106,'C#8':109,'D#8':111,'F#8':114,'G#8':116,
}
export const noteNumbers_reverse={
    21:'A0',22:'Bb0',23:'B0',24:'C1',25:'Db1',26:'D1',27:'Eb1',28:'E1',
    29:'F1',30:'Gb1',31:'G1',32:'Ab1',33:'A1',34:'Bb1',35:'B1',36:'C2',
    37:'Db2',38:'D2',39:'Eb2',40:'E2',41:'F2',42:'Gb2',43:'G2',44:'Ab2',
    45:'A2',46:'Bb2',47:'B2',48:'C3',49:'Db3',50:'D3',51:'Eb3',52:'E3',
    53:'F3',54:'Gb3',55:'G3',56:'Ab3',57:'A3',58:'Bb3',59:'B3',60:'C4',
    61:'Db4',62:'D4',63:'Eb4',64:'E4',65:'F4',66:'Gb4',67:'G4',68:'Ab4',
    69:'A4',70:'Bb4',71:'B4',72:'C5',73:'Db5',74:'D5',75:'Eb5',76:'E5',
    77:'F5',78:'Gb5',79:'G5',80:'Ab5',81:'A5',82:'Bb5',83:'B5',84:'C6',
    85:'Db6',86:'D6',87:'Eb6',88:'E6',89:'F6',90:'Gb6',91:'G6',92:'Ab6',
    93:'A6',94:'Bb6',95:'B6',96:'C7',97:'Db7',98:'D7',99:'Eb7',100:'E7',
    101:'F7',102:'Gb7',103:'G7',104:'Ab7',105:'A7',106:'Bb7',107:'B7',108:'C8'}    
export function hasEvents(line){
    for (let i = 0; i < line.length; i++){
        if (line[i] !== '') return true
    }
    return false
}
export function createMidiObj(chordArray,lengthArray,bpm,volume){
    var linesHaveEvents = []
    for (let i = 0; i< chordArray.length;i++){
        if (hasEvents(chordArray[i])) linesHaveEvents.push(i)
    }
    if (linesHaveEvents.length === 0) return null
    var reverbTime = 20 //ticks 
    var tracks = []
    var microsecPerBeat = 60000000. / bpm
    var ticksPerBeat = 256
    tracks[0] = []
    tracks[0].push({
        deltaTime: 0,
        type: 'meta',
        subtype: 'setTempo',
        microsecondsPerBeat: microsecPerBeat
      })
    tracks[0].push({ 
        deltaTime: chordArray[0].length*ticksPerBeat+1,
        type: 'meta',
        subtype: 'endOfTrack'
    })
    for (let i = 0; i < linesHaveEvents.length;i++){
        //iterate over lines that have events
        let ticksToEvent = 0
        tracks[i+1] = []
        tracks[i+1].push({
            deltaTime: 0,
            channel: i,//channel = instrument index. row index here
            type: 'channel',
            subtype: 'programChange',
            programNumber: i
        })
        ///meta events i.e trackName(instrument name) not needed
        let lineID = linesHaveEvents[i]
        for (let j = 0; j < chordArray[lineID].length;j++){
            //iterate over cells of lines that have events
            //console.log(lineID, j, chordArray[lineID][j])
            if (chordArray[lineID][j]===''){
                //NemptyPrior +=1
                if (chordArray[lineID][j-1] && chordArray[lineID][j-1]!==''){
                    let prevNotes = NoteOps.separateNotes(chordArray[lineID][j-1])
                                    .map((note) => NoteOps.refineNote(note))
                    for (let k = 0; k < prevNotes.length;k++){
                        tracks[i+1].push({
                                deltaTime: k===0?ticksPerBeat + reverbTime:0,
                                channel: i,
                                type: 'channel',
                                noteNumber: noteNumbers[prevNotes[k]],
                                velocity: volume,
                                subtype: 'noteOff'
                            })
                    }
                    ticksPerBeat = ticksPerBeat - reverbTime
                }
                else ticksToEvent += ticksPerBeat

            }
            else{
                //console.log(chordArray[lineID],lineID)
                //console.log(lengthArray[lineID],lineID)
                
                if (chordArray[lineID][j] === chordArray[lineID][j-1]){  
                    if (lengthArray[lineID][j-1] === 1){
                        ticksToEvent +=ticksPerBeat
                    }
                    else{
                        let notes = NoteOps.separateNotes(chordArray[lineID][j]).map((note) => NoteOps.refineNote(note))
                        for (let k = 0; k < notes.length;k++){
                            tracks[i+1].push({
                                    deltaTime: k===0?(ticksToEvent-reverbTime):0,
                                    channel: i,
                                    type: 'channel',
                                    noteNumber: noteNumbers[notes[k]],
                                    velocity: volume,
                                    subtype: 'noteOff'
                                })                 
                        }
                        for (let k = 0; k < notes.length;k++){
                            tracks[i+1].push({
                                    deltaTime: k===0?reverbTime:0,
                                    channel: i,
                                    type: 'channel',
                                    noteNumber: noteNumbers[notes[k]],
                                    velocity: volume,
                                    subtype: 'noteOn'
                                })                 
                        }
                        
                        ticksToEvent = ticksPerBeat
                    }
                }else{
                    let notes = NoteOps.separateNotes(chordArray[lineID][j]).map((note) => NoteOps.refineNote(note))
                    for (let k = 0; k < notes.length;k++){
                        tracks[i+1].push({
                                deltaTime: k===0?ticksToEvent:0,
                                channel: i,
                                type: 'channel',
                                noteNumber: noteNumbers[notes[k]],
                                velocity: volume,
                                subtype: 'noteOn'
                            })                 
                    }
                    ticksToEvent = ticksPerBeat
                
                    if (chordArray[lineID][j-1] && chordArray[lineID][j-1]!==''){
                        let prevNotes = NoteOps.separateNotes(chordArray[lineID][j-1]).map((note) => NoteOps.refineNote(note))
                        for (let k = 0; k < prevNotes.length;k++){
                            tracks[i+1].push({
                                    deltaTime: k===0?reverbTime:0,
                                    channel: i,
                                    type: 'channel',
                                    noteNumber: noteNumbers[prevNotes[k]],
                                    velocity: volume,
                                    subtype: 'noteOff'
                                })
                        }
                        ticksPerBeat -=reverbTime
                    }
                }
            }                 
            
        }
        tracks[i+1].push(
            { deltaTime: ticksToEvent+ 1, type: 'meta', subtype: 'endOfTrack' }
        )
    }
    var header = {
        //'formatType': formatType,
        'trackCount': tracks.length,
        'ticksPerBeat': ticksPerBeat
    }
    return {
        'header': header,
        'tracks': tracks
    }
}

export function fireEventEnded(target) {//target is e.g button
    if (! target) return;    
}
export function Play(chordArray,lengthArray,bpm,volume){
    console.log('bpm =',bpm)
    var midiObj = createMidiObj(chordArray,lengthArray,bpm,volume);
    var synth = Synth(44100);
    var replayer = Replayer(midiObj, synth);
    var player = AudioPlayer(replayer,volume);

}

export function AudioPlayer(generator, volume,opts) {
    if (!opts) opts = {};
    //var latency = opts.latency || 1;only for flash and moz
    //var checkInterval = latency * 100 /* in ms/ 
    var webkitAudio = window.AudioContext || window.webkitAudioContext;
    //var requestStop = false;

    if (webkitAudio) {
        // Uses Webkit Web Audio API if available
        var context = new webkitAudio(); // fixed by this. XC.
        sampleRate = context.sampleRate;
        //console.log(' in webkit Audio line 411')
        var channelCount = 2;
        var bufferSize = 4096*4; // Higher for less gitches, lower for less latency
        
        //context.decodeAudioData(extbuf,(b) => toadd = b);
        var node = context.createScriptProcessor(bufferSize, 0, channelCount);
        node.onaudioprocess = function(e) { 
            //console.log('running process now ',e)
            process(e)
        };

        function process(e) {
            if (generator.finished) {
                node.disconnect();
                //alert('done: ' + targetElement); // xc.
                //fireEventEnded(targetElement); // xc.
                return;
            }
            //same generator, what's different at each process is nextEventIndex
            //audio keeps running while nextEvent is found and started
            var dataLeft = e.outputBuffer.getChannelData(0);
            var dataRight = e.outputBuffer.getChannelData(1);
            var generated = generator.generate(bufferSize);
            //create each Note by noteOn with bufferSize = num of Samples
            for (var i = 0; i < bufferSize; ++i) {
                dataLeft[i] =
                 generated[i*2]*volume/10.;
                //console.log('eft',dataLeft[i])
                dataRight[i] = generated[i*2+1]*volume/10.;
            }
        }
        
        // start
        node.connect(context.destination);
        
        console.log('done playing')
        return {
            'stop': function() {
                // pause
                node.disconnect();
                //requestStop = true;
            },
            'type': 'Webkit Audio'
        }

    }
}


export function Replayer(midiObj, synth) {/// return Object with function props
    var trackStates = [];
    var beatsPerMinute = 120;
    let ticksPerBeat = midiObj.header.ticksPerBeat;
    var channelCount = 16;

    for (var i = 0; i < midiObj.tracks.length; i++) {
        trackStates[i] = {
            'nextEventIndex': 0,
            'ticksToNextEvent': (
                midiObj.tracks[i].length ?
                    midiObj.tracks[i][0].deltaTime :
                    null
            )
        };
    }
    
    function Channel() {
        //console.log('doing Channel')
        var generatorsByNote = {};
        var currentProgram = PianoProgram;
        
        function noteOn(note, velocity) {
            //console.log('creating Note ', note)
            if (generatorsByNote[note] && !generatorsByNote[note].released) {
                /* playing same note before releasing the last one. BOO */
                generatorsByNote[note].noteOff(); /* TODO: check whether we ought to be passing a velocity in */
            }
            var generator = currentProgram.createNote(note, velocity);// this only decides the method to be used (not used yet!)
            synth.addGenerator(generator);
            
            generatorsByNote[note] = generator;
        }
        function noteOff(note, velocity) {
            if (generatorsByNote[note] && !generatorsByNote[note].released) {
                //console.log('off',generatorsByNote[note])
                generatorsByNote[note].noteOff(velocity);
            }
        }
        function setProgram(programNumber) {
            //console.log('setting program for Channel', programNumber)
            currentProgram = PROGRAMS[programNumber] || PianoProgram;
        }
        
        return {
            'noteOn': noteOn,
            'noteOff': noteOff,
            'setProgram': setProgram
        }
    }
    
    var channels = [];
    for (let i = 0; i < channelCount; i++) {
        channels[i] = Channel();
    }
    
    var nextEventInfo;
    var samplesToNextEvent = 0;
    
    function getNextEvent() {
        //console.log('getting Next Event')
        var ticksToNextEvent = null;
        var nextEventTrack = null;
        var nextEventIndex = null;
        
        for (var i = 0; i < trackStates.length; i++) {
            //console.log('trackstate', i)
            if (
                trackStates[i].ticksToNextEvent != null
                && (ticksToNextEvent == null || trackStates[i].ticksToNextEvent < ticksToNextEvent)
            ) {
                ticksToNextEvent = trackStates[i].ticksToNextEvent;
                nextEventTrack = i;
                nextEventIndex = trackStates[i].nextEventIndex;
            }
        }
        if (nextEventTrack != null) {
            /* consume event from that track */
            var nextEvent = midiObj.tracks[nextEventTrack][nextEventIndex];
            if (midiObj.tracks[nextEventTrack][nextEventIndex + 1]) {
                trackStates[nextEventTrack].ticksToNextEvent += midiObj.tracks[nextEventTrack][nextEventIndex + 1].deltaTime;
            } else {
                trackStates[nextEventTrack].ticksToNextEvent = null;
            }
            trackStates[nextEventTrack].nextEventIndex += 1;//iterating through nextEventIndex eg 100,101,...
            /* advance timings on all tracks by ticksToNextEvent */
            for (let i = 0; i < trackStates.length; i++) {
                if (trackStates[i].ticksToNextEvent != null) {
                    trackStates[i].ticksToNextEvent -= ticksToNextEvent
                }
            }
            nextEventInfo = {///to be used in handleEvent
                'ticksToEvent': ticksToNextEvent,
                'event': nextEvent,
                'track': nextEventTrack
            }
            var beatsToNextEvent = ticksToNextEvent / ticksPerBeat;
            var secondsToNextEvent = beatsToNextEvent / (beatsPerMinute / 60);
            samplesToNextEvent += secondsToNextEvent * synth.sampleRate;
        } else {
            nextEventInfo = null;
            samplesToNextEvent = null;
            self.finished = true;
        }
    }
    
    getNextEvent();

    function generate(samples) {
        var data = new Array(samples*2);
        var samplesRemaining = samples;
        var dataOffset = 0;
        
        while (true) {
            if (samplesToNextEvent != null && samplesToNextEvent <= samplesRemaining) {
                /* generate samplesToNextEvent samples, process event and repeat */
                var samplesToGenerate = Math.ceil(samplesToNextEvent);
                if (samplesToGenerate > 0) {
                    ////can generate first if generators are saved from 
                    /// previous handle NoteOn to establish data model method
                    /// because synth is already initiated
                    synth.generateIntoBuffer(samplesToGenerate, data, dataOffset);
                    dataOffset += samplesToGenerate * 2;
                    samplesRemaining -= samplesToGenerate;
                    samplesToNextEvent -= samplesToGenerate;
                }                
                
                handleEvent();
                getNextEvent();

                
            } else {
                /* generate samples to end of buffer */
                if (samplesRemaining > 0) {
                    synth.generateIntoBuffer(samplesRemaining, data, dataOffset);
                    samplesToNextEvent -= samplesRemaining;
                }
                break;
            }
        }
        return data;
    }
    
    function handleEvent() {
        var event = nextEventInfo.event;
        switch (event.type) {
            default:
                break;
            case 'meta':
                switch (event.subtype) {
                    default:
                        break;
                    case 'setTempo':
                        beatsPerMinute = 60000000 / event.microsecondsPerBeat
                }
                break;
            case 'channel':
                switch (event.subtype) {
                    default:
                        break;
                    case 'noteOn'://won't have sound if this isn't on
                        //console.log('handle',event.noteNumber, event.velocity)
                        channels[event.channel].noteOn(event.noteNumber, event.velocity);
                        break;
                    case 'noteOff':
                        channels[event.channel].noteOff(event.noteNumber, event.velocity);
                        break;
                    case 'programChange':
                        channels[event.channel].setProgram(event.programNumber);
                        break;
                }
                break;
        }
    }
    
    function replay(audio) {
        console.log('replay');
        audio.write(generate(44100));
        setTimeout(function() {replay(audio)}, 10);
    }
    
    var self = {
        'replay': replay,
        'generate': generate,
        'finished': false
    }
    return self;
}

export function SineGenerator(freq) {
    var self = {'alive': true};
    var period = sampleRate / freq;
    var t = 0;
    
    self.generate = function(buf, offset, count) {
        //console.log('Sine generating')
        for (; count; count--) {
            var phase = t / period;
            var result = Math.sin(phase * 2 * Math.PI);
            buf[offset++] += result;
            buf[offset++] += result;
            t++;
        }
    }    
    return self;
}

export function SquareGenerator(freq, phase) {
    var self = {'alive': true};
    var period = sampleRate / freq;
    var t = 0;
    
    self.generate = function(buf, offset, count) {
        for (; count; count--) {
            var result = ( (t / period) % 1 > phase ? 1 : -1);
            buf[offset++] += result;
            buf[offset++] += result;
            t++;
        }
    }
    
    return self;
}

export function ADSRGenerator(child, attackAmplitude, sustainAmplitude, attackTimeS, decayTimeS, releaseTimeS) {
    var self = {'alive': true}
    var attackTime = sampleRate * attackTimeS;
    var decayTime = sampleRate * (attackTimeS + decayTimeS);
    var decayRate = (attackAmplitude - sustainAmplitude) / (decayTime - attackTime);
    var releaseTime = null; /* not known yet */
    var endTime = null; /* not known yet */
    var releaseRate = sustainAmplitude / (sampleRate * releaseTimeS);
    var t = 0;
    
    self.noteOff = function() {
        if (self.released) return;
        releaseTime = t;
        self.released = true;
        endTime = releaseTime + sampleRate * releaseTimeS;
    }
    
    self.getmodel = function(buf, offset, count) {
        //console.log('generating in ADSR')
        //console.log('original buf +offet',buf,offset)
        if (!self.alive) return;
        var input = new Array(count * 2);
        for (var i = 0; i < count*2; i++) {
            input[i] = 0;
        }
        child.generate(input, 0, count);//SineGenerator or Square
        
        let childOffset = 0;
        while(count) {
            if (releaseTime != null) {
                if (t < endTime) {
                    /* release */
                    while(count && t < endTime) {
                        let ampl = sustainAmplitude - releaseRate * (t - releaseTime);
                        buf[offset++] += input[childOffset++] * ampl;
                        buf[offset++] += input[childOffset++] * ampl;
                        t++;
                        count--;
                    }
                } else {
                    /* dead */
                    self.alive = false;
                    return;
                }
            } else if (t < attackTime) {
                /* attack */
                while(count && t < attackTime) {
                    let ampl = attackAmplitude * t / attackTime;
                    buf[offset++] += input[childOffset++] * ampl;
                    buf[offset++] += input[childOffset++] * ampl;
                    t++;
                    count--;
                    //console.log('count',count,childOffset,offset)
                    //console.log(t,buf)
                }
            } else if (t < decayTime) {
                /* decay */
                while(count && t < decayTime) {
                    let ampl = attackAmplitude - decayRate * (t - attackTime);
                    buf[offset++] += input[childOffset++] * ampl;
                    buf[offset++] += input[childOffset++] * ampl;
                    t++;
                    count--;
                }
            } else {
                /* sustain */
                while(count) {
                    buf[offset++] += input[childOffset++] * sustainAmplitude;
                    buf[offset++] += input[childOffset++] * sustainAmplitude;
                    t++;
                    count--;
                }
            }
        }
    }
    
    return self;
}

export function midiToFrequency(note) {
    return 440 * Math.pow(2, (note-69)/12);
}

export const PianoProgram = {
    'attackAmplitude': 0.2,
    'sustainAmplitude': 0.1,
    'attackTime': 0.02,
    'decayTime': 0.3,
    'releaseTime': 0.02,
    'createNote': function(note, velocity) {
        var frequency = midiToFrequency(note);
        return ADSRGenerator(
            SineGenerator(frequency),
            this.attackAmplitude * (velocity / 128), this.sustainAmplitude * (velocity / 128),
            this.attackTime, this.decayTime, this.releaseTime
        );
    }
}


export const PROGRAMS = {
    0: PianoProgram,
    1: PianoProgram
};

export function Synth(sampleRate) {
    console.log('synthing')
    var generators = [];
    
    function addGenerator(generator) {
        //console.log('to push', generator)
        generators.push(generator);

        //console.log('after push', generators)
    }
    
    function generateSynth(samples) {
        var data = new Array(samples*2);
        generateIntoBuffer(samples, data, 0);
        return data;
    }
    
    function generateIntoBuffer(samplesToGenerate, buffer, offset) {
        for (let i = offset; i < offset + samplesToGenerate * 2; i++) {
            buffer[i] = 0;
        }
        for (let i = generators.length - 1; i >= 0; i--) {
            //generators are ADSRgernerator obj
            generators[i].getmodel(buffer, offset, samplesToGenerate);
            if (!generators[i].alive) generators.splice(i, 1);
        }
    }
    
    return {
        'sampleRate': sampleRate,
        'addGenerator': addGenerator,
        'generateSynth': generateSynth,
        'generateIntoBuffer': generateIntoBuffer
    }
}

