import {FaToggleOn,FaToggleOff} from 'react-icons/fa'
const ModeToggle = ({darkmode,giveClass,controlDarkMode}) => {
    return (
        darkmode?
        <FaToggleOff className={giveClass} style={{ cursor:'pointer'}} onClick={controlDarkMode}/>
        :<FaToggleOn className={giveClass} style={{ cursor:'pointer'}} onClick={controlDarkMode}/>
        
    )
}

export default ModeToggle
