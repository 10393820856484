import { useState,useEffect } from "react"
import Button from "../Button"
import { Link } from 'react-router-dom'
const Burger = ({toggleModes,setShowApp,openInventory,showInventory,showAddtool,openAddtool,
    showPlaytool,openPlaytool}) => {
    const [showButtons, setShowButtons] = useState(false)
    const toggleShowButtons =()=>{
        setShowButtons(!showButtons)
        toggleModes()
    }
    useEffect(() => {
        if (showButtons === true){
        const timeId = setTimeout(() => {
          // After 15 seconds set the show value to false
          setShowButtons(false)
        }, 18000)
        return () => {
        clearTimeout(timeId)
        }}
    }, [showButtons]);

    return (
        <div><div className="burger" onClick={toggleShowButtons}>
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
            </div>
            <Link to='/' onClick={()=>setShowApp(true)}><div className='button-bar' style={{display:showButtons?'block':'none'}} >
            <Button classname='btn-mobile btn-1' color ={showInventory? 'rgb(180,0,255)':'rgb(200, 90, 255)'} text ={'Inventory'} onClick={openInventory}/>
            <Button classname='btn-mobile' color ={showAddtool? 'rgb(150,0,220)':'rgb(165, 90, 255)'} text ={showAddtool?'Addbox X':'Add Bytes!'} onClick={openAddtool}/>
            <Button classname='btn-mobile btn-3' color ={showPlaytool? 'rgb(132,3,197)':'rgb(132,3,220)'}
            text ={showPlaytool?'Playbox X':'Play Bytes'} onClick={openPlaytool} />
            </div></Link>
        </div>
    )
}

export default Burger
