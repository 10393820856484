const PopUpError = ({errors,togglePopup}) => {
    return (
        <div className='popup'>
            <div className='popup-inner'>
            <h3>Your chord(s) aren't valid due to following possile errors. Please fix.</h3>
            {errors.map((error,id)=><p className='save-error' key={id} > &#8226; {error}</p>)}      
            <button className='popup-btn' style = {{backgroundColor:'rgb(180,0,255)'}} onClick={()=>togglePopup()}>OK</button> 
            </div>
        </div>
    )
}

export default PopUpError
