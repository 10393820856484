import { useState, useEffect } from "react"
import Key from "./Key"
import {FaExchangeAlt,FaGripLines,FaAngleDoubleDown,FaCircleNotch} from 'react-icons/fa'
import { keynames,Play} from "./utils"
//import {playSound } from "./playPianoKeys"
const MainPiano = ({classname,top,width,noteVol,sharedChord,setSharedChord,pinItem}) => {
    
    const [isShown, setIsShown] = useState(false);
    const [synchInsert, setSynchInsert] = useState(true)
    const [synchToMix, setSynchToMix] = useState(false)
    const [pressedKey, setPressedKey] = useState('')
    const [playedKey, setPlayedKey] = useState(null)
    const copyToAdd=(keyname)=>{
        synchInsert && setSharedChord(sharedChord+keyname)
        synchToMix && pinItem(keyname)
        setPlayedKey(keyname)
    }
    useEffect(() => {
        if (playedKey && noteVol !== 0){
            //playSound(playedKey,noteVol)
            Play([[playedKey]],[Array(1).fill(0)],100,noteVol*18.)
            setPlayedKey(null)
        }
        else{
            const timeId = setTimeout(() => {
                setPlayedKey(null)
            }, 100)
            return () => {
              clearTimeout(timeId)
            }}
    }, [playedKey,noteVol])
    const MixOnInsertOff=()=>{
        setSynchToMix(true)
        setSynchInsert(false)
    }
    return (
        <div style={{top:top,width:width,boxShadow:isShown? '0 0 2px hsla(0deg, 0%, 0%, 0.5)':'none'}} 
        className={classname}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)} >
            {synchInsert?
            <FaExchangeAlt className='sync-insert' onClick={()=>setSynchInsert(!synchInsert)}/>
            :<FaGripLines className='sync-insert-off' onClick={()=>setSynchInsert(!synchInsert)}/>
            }
            {synchToMix?
            <FaAngleDoubleDown className='to-mixpad tomix-on' onClick={()=> setSynchToMix(!synchToMix)} />
            :<FaCircleNotch className='to-mixpad tomix-off' onClick={()=> MixOnInsertOff()}/>}
            <div className='pressedkey'>{pressedKey}</div>
            <div className='piano'>
            { keynames.map((keyname)=>
            (<Key key={keyname} keyname={keyname} givePressedKey={setPressedKey} 
                synchInsert={synchInsert} copyToAdd={copyToAdd}
                synchToMix={synchToMix} />))
            }
            </div>            
        </div>
    )
}

export default MainPiano
