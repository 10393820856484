import { useState,useEffect } from 'react';
import PropTypes from 'prop-types'
import {FaChevronLeft,FaChevronRight,FaChevronDown,FaChevronUp} from 'react-icons/fa'
import Collection from './Collection'
import FilterChord from './FilterChord'
import PhraseDic from './PhraseDic';
import AddItem from './AddItem';
import PinItem from './PinItem';
//import { Play } from './utils'
const PhraseBoard =({classname,isMobile,isDark,noteVol,
    collection,setCollection,showCollection,
    phrases,onFilter,resetFilter,delPhrase,sharedChord,addSharedChord,
    pinItem,downsize,changePanelSize,maxHeight,changeHeight}) => {
    const [isShown, setIsShown] = useState(false)
    const [clickedId, setClickedId] = useState(0)
    const [ItemToUse, setItemToUse] = useState('')
    const [phraseInfo, setPhraseInfo] = useState('')
    const [PlayOn, setPlayOn] = useState(false)
    useEffect(() => {
      if (clickedId!==0){
      const timeId = setTimeout(() => {
        // After 15 seconds set the show value to false
        setClickedId(0)
      }, 15000)
      return () => {
        clearTimeout(timeId)
      }}
    }, [clickedId])
    useEffect(() => {
      if (phraseInfo!==''){
      const timeId = setTimeout(() => {
        setPhraseInfo('')
      }, 15000)
      return () => {
        clearTimeout(timeId)
      }}
    }, [phraseInfo])
    /*useEffect(() => {
      if (PlayOn && ItemToUse !== ''){
        Play(ItemToUse.split(' '),Array(ItemToUse.split(' ').length).fill(0),100,noteVol*1.8)
        setPlayOn(false)
      }
      
    }, [PlayOn,ItemToUse,noteVol])*/

    const doFilter =(keyword)=>{
      onFilter(keyword)
    }
    const onDelete = (id) =>{
        delPhrase(id)// shame! has to repeat the pointer to the function here
    }
    const copyToAdd =(chord) =>{
        addSharedChord(sharedChord+chord)
    }
    const pressToAdd =()=>{
      addSharedChord(sharedChord+ItemToUse)
      setClickedId(0)
      setItemToUse('')
    }

    const copyToUse =() =>{
      setClickedId(0)
      pinItem(ItemToUse)
      setItemToUse('')
    }
    return (
        <div className={classname} 
        style={isMobile?{display:'block',height:maxHeight?'680px':'310px'}:{width:!isMobile&&(downsize?'59%':'32%'),left:!isMobile&&(downsize?'33%':'60%'),
        height:maxHeight?'680px':'310px', boxShadow:isShown? '0 0 2px hsla(0deg, 0%, 0%, 0.5)':'none'}}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}>
            <FilterChord formclass={isDark?'addform-inventory addform-inventory-dark':'addform-inventory'} isDark={isDark}
            collection={collection} setCollection={setCollection}
            onFilter={onFilter} resetFilter={resetFilter}
            placeholdertext ='Quick find saved phrases'/>        
            <PhraseDic isDark={isDark} onFilter={doFilter} maxHeight={maxHeight} noteVol={noteVol}
              phrases={phrases} clickedId={clickedId} setClickedId={setClickedId}
              setPhraseInfo={setPhraseInfo} onDelete={onDelete} setPlayOn={setPlayOn}
              copyToAdd={copyToAdd} setItemToUse={setItemToUse} collection={collection}/>

            {isMobile? null:(downsize? 
              (<FaChevronRight className={isDark?'slide-phraseboard slide-phraseboard-dark':'slide-phraseboard'} 
              style={{display:isShown? 'block':'none'}} onClick={changePanelSize}/>)
              :(<FaChevronLeft className={isDark?'slide-phraseboard slide-phraseboard-dark':'slide-phraseboard'}
              style={{display:isShown? 'block':'none'}} onClick={changePanelSize}/>) 
            )}
            {maxHeight?
            (<FaChevronUp className={isDark?'slide-vertical-phraseboard slide-vertical-phraseboard-dark':'slide-vertical-phraseboard'} 
            style={{display:isShown? 'block':'none'}} onClick={changeHeight}/>)
            :(<FaChevronDown className={isDark?'slide-vertical-phraseboard slide-vertical-phraseboard-dark':'slide-vertical-phraseboard'} 
             style={{display:isShown? 'block':'none'}} onClick={changeHeight}/>)
            }
            <Collection collection={collection} setCollection={setCollection} showCollection={showCollection}
            classname='collection-phrase'/>  
            {clickedId!==0 && phraseInfo? <div className='chord-info chord-info-phrase'> {phraseInfo} </div>:null }
            {clickedId!==0? <AddItem classname='add-item-phrase' addFunc={pressToAdd}/>:null}
            {clickedId!==0? <PinItem classname='pin-item-phrase' mixFunc={copyToUse} />:null}
        </div>
    )
}
PhraseBoard.defaultProps ={
  isMobile:false,
  //toDisplay: true,
}
PhraseBoard.propTypes ={
  isMobile: PropTypes.bool,
  //toDisplay: PropTypes.bool
}
export default PhraseBoard
