import {FaVolumeMute,FaVolumeDown,FaVolumeUp} from 'react-icons/fa'
const NoteVolume = ({noteVol,controlNoteVol,noteVolClass}) => {
    return (
        noteVol === 0? 
        <FaVolumeMute className={noteVolClass} onClick={() => controlNoteVol()}/>
        :(noteVol === 0.5? 
            <FaVolumeDown className={noteVolClass} onClick={() => controlNoteVol()} />
            :<FaVolumeUp className={noteVolClass} onClick={() => controlNoteVol()} />                
        )        
    )
}

export default NoteVolume

