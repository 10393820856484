
import cover from './Abstract.svg';
import cover_dark from './Business_decisions.svg'
import Button from '../Button';
const Welcome = ({isMobile,closeWelcome,darkmode,openInventory,openAddtool}) => {
    const handleClick = ()=>{
        openInventory(true)
        openAddtool(true)
        closeWelcome(false)
    }
    return (
        <div className='welcome'>
            <div className={darkmode?'circle dark':'circle light'}></div>
            <div className='welcome-text'>
            <h2>Create your <br/> <span>MuseBytes</span> </h2>
            <h2 style={{fontSize: '20px'}}>The building blocks to your songs &#38; compositions</h2>
            </div>
            
            <Button classname='btn-welcome' color = 'rgb(255, 94, 0)' text ={'Get started !'} 
            onClick={handleClick}/>
            <img src={darkmode?cover_dark:cover} alt="MuseByte Bg" 
            className={darkmode?'cover-dark':'cover'}/>
        </div>
    )
}

export default Welcome
